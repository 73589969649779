import { useEffect, useState } from "react";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import "./info-certify-box.scss";

const InfoCertifyBox = ({ setScreen, isConnected, rewardsPerEpoch }) => {
  const [isActive, setIsActive] = useState();

  if (isActive === undefined) {
    setIsActive(rewardsPerEpoch !== "0");
  }

  useEffect(() => {
    if (isActive && isConnected) {
      setScreen("secondScreen");
      return;
    }
  }, [
    isActive, isConnected
  ]);
  if (isConnected) {
    return (
      <>
        <div className="box main_border">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div
                className={
                  window.screen.width <= 400
                    ? "box w-100 mt-4"
                    : "box w-80 mt-4"
                }
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="blue-title">
                      {!isActive && (
                        <>
                          The E-poch is over, and the Pool has been drained of
                          Rewards! 💧
                          <br />
                          Refill it by adding new incentives and let the trading
                          begin! 🚀
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center">
              {!isActive && (
                <p className="w-70 text-center">
                  Cannot verify trades at this moment because there are no
                  rewards.
                </p>
              )}
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 d-flex justify-content-center text-center">
              <p className="w-70">
                Remember, the beauty of zkMakers is that we never keep your API
                Keys Your APIs are stored in your local device, we use Zero
                Knowledge technology to safely and privately prove your trading
                records. Learn more
                <a
                  className="link"
                  target="_blank"
                  href="https://zkmakers.gitbook.io/zkmakers/zkmakers-protocol/reward-pools"
                  >
                  {" "}
                  here.
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="box main_border">
        <ConnectYourWalletFirst noMargins={true} />
      </div>
    );
  }
};

export default InfoCertifyBox;
