import { createSlice } from '@reduxjs/toolkit';

export const pinnedPoolsSlice = createSlice({
    name: 'pinnedPools',
    initialState: {},
    reducers: {
        save: (state, action) => {
            let pools = {};
            Object.keys(action.payload).map(id => {
                pools[id] = action.payload[id];
            });
            state.all = pools;
        }
    }
});

export const { save } = pinnedPoolsSlice.actions;
const pinnedPoolsSliceReducers = pinnedPoolsSlice.reducer;
export default pinnedPoolsSliceReducers;