import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import PoolABI from "../../../assets/abis/pool.json";
import WaveButton from "../../buttons/wave-button";
import "../../buttons/claim-button.scss";
import Button from "../../buttons/button";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { refresh } from "../../../features/refresh-slice";
import { HandleRevertMessage } from "../../error-messages/error-messages";

const ClaimRebateButton = ({
  text,
  customLinkClass,
  customButtonClass,
  addressPool,
  isConnected,
  epochsToClaim
}) => {
  const [ isOnMMProcess, setIsOnMMProcess ] = useState(false);
  const dispatch = useDispatch();

  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { 
    config: claimConfig,
    error: claimPrepareError 
  } = usePrepareContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    address: addressPool,
    abi: PoolABI,
    functionName: "multiClaimRebateRewards",
    args: [epochsToClaim],
    enabled:
      isConnected && Array.isArray(epochsToClaim) && epochsToClaim.length > 0
  });

  const { 
    data: claimData, 
    write: claimWrite, 
    error: claimWriteError,
    isError: claimWriteIsError,
    reset: claimWriteReset,
    success: claimWriteSuccess,
    isLoading: isLoadingClaim 
  } = useContractWrite(claimConfig);

  const { data: claimWaitData, isLoading: isLoadingClaimWait, isSuccess: isSuccessClaim } =
  useWaitForTransaction({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    hash: claimData?.hash,
  });

  useEffect(() => {
    if (claimPrepareError) {
      setIsOnMMProcess(false);
      claimWriteReset();
      return HandleRevertMessage(claimPrepareError);
    }
  }, [claimPrepareError, claimWriteReset]);

  useEffect(() => {
    if (claimWriteIsError) {
      setIsOnMMProcess(false);
      claimWriteReset();
      return HandleRevertMessage(claimWriteError);
    }
  }, [claimWriteError, claimWriteIsError, claimWriteReset]);

  // use effects
  useEffect(() => {
    setIsOnMMProcess(!!isLoadingClaimWait);
  }, [isLoadingClaimWait]);

  useEffect(() => {
    //conditional behaviors
    if (isOnMMProcess) {
      if(isSuccessClaim) {
        if(claimWaitData){
          if(claimWaitData.status === 1) {
            NotificationManager.success(
              "Pool claimed succesfully",
              "Done!",
              process.env.NOTIFICATION_TIME
              );
              dispatch(refresh({name: 'adminClaimablePools', value: true}));
          }else if(claimWaitData.status === 0) {
            NotificationManager.error(
              "Transaction failed",
              "Error!",
              process.env.NOTIFICATION_TIME
              );
          }else{
            NotificationManager.warning(
              "Unknown Transaction state received",
              "Warning!",
              process.env.NOTIFICATION_TIME
              );
          }
        }
        
      }else if(!isLoadingClaimWait) {
        setIsOnMMProcess(false);
        claimWriteReset();
      }
    }
  }, [
    dispatch,
    isSuccessClaim, 
    isOnMMProcess, 
    isLoadingClaimWait, 
    claimWaitData?.status
  ]);


  if (!epochsToClaim || (Array.isArray(epochsToClaim) && epochsToClaim.length === 0)) {
    return; // ToDo Show that there is nothing to claim
  }

  if (chain.id.toString() !== process.env.REACT_APP_CHAIN_ID) {
    return (
      <div>
        <WaveButton
          text="Change Network to claim"
          customLinkClass={customLinkClass}
          customButtonClass={customButtonClass}
          onClick={() =>
            switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
          }
        />
      </div>
    );
  }

    return (
      <div>
          {(()=> {
            if (chain.id.toString() !== process.env.REACT_APP_CHAIN_ID) {
              return (
                <WaveButton
                  text="Change Network to claim"
                  customLinkClass={customLinkClass}
                  customButtonClass={customButtonClass}
                  onClick={() =>
                    switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
                  }
                />
              )
            }

            if (!isLoadingClaim && !isLoadingClaimWait && !isOnMMProcess) {
              return (
                <WaveButton
                  id="button-claim-rebate"
                  customLinkClass={customLinkClass}
                  customButtonClass={customButtonClass}
                  onClick={() => claimWrite?.()}
                  text={text}
                />
              )
            }else{
              return <Button text="Claiming…" type="button-disabled" />
            }
        })()}
    </div>
  )
};

export default ClaimRebateButton;