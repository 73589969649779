import { ethers } from "ethers";

export const READING_ERC20 = -2;
export const OK_ERC20 = -1;
export const ERROR_ERC20_TOKENA = 0;
export const ERROR_ERC20_TOKENB = 1;

async function getIsERC20({ address, provider }) {
    if(!ethers.utils.isAddress( address )) {
        return false;
    }

    let bytecode = await provider.getCode(address);

    // No code : "0x" then decimals is definitely not there
    if (bytecode.length <= 2) {
        return false;
    }

    const contract = new ethers.Contract(
        address,
        ["function decimals() view returns (uint8)"],
        provider
    );

    // If gas estimation doesn't revert then an execution is possible
    // given the provided function selector
    try {
        await contract.estimateGas.decimals();
        return true;
    } catch {
        // Otherwise (revert) we assume that there is no entry in the jump table
        // meaning that the contract doesn't include decimals()
        return false;
    }
}

export async function getAreAllERC20({
    addresses,
    provider,
    setFailAreAllERC20
}) {
    //retun values:
    // -2 = checking in process
    // -1 = ok all erc20
    // 0 = tokenA is not valid
    // 1 = tokenB is not valid
    let flag = -2;
    let numAddressesChecked = 0;
    const numAddresses = addresses.length
    for(let iAddress = 0; iAddress < numAddresses; iAddress++) {
        if(flag === READING_ERC20 && !await getIsERC20({
            address: addresses[iAddress],
            provider
        })){
            flag = iAddress;
            break;
        }
        numAddressesChecked++;
    }

    if(
        flag === READING_ERC20
        && 
        numAddressesChecked === numAddresses
    ){
        flag = OK_ERC20;
    }
    setFailAreAllERC20(flag);
}
export default getAreAllERC20;
