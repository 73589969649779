import {
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useSwitchNetwork,
  useWaitForTransaction,
} from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { usePendingRewards } from "../../services/use-pending-rewards";
import WaveButton from "../buttons/wave-button";
import "./claim-button.scss";
import { useEffect, useState } from "react";
import { HandleRevertMessage } from "../error-messages/error-messages";
import { NotificationManager } from "react-notifications";
import { refresh } from "../../features/refresh-slice";
import { useDispatch } from "react-redux";

const ClaimButton = ({
  text,
  customLinkClass,
  customButtonClass,
  addressPool,
  currentEpoch,
  isConnected,
  userAddress,
}) => {
  const { epochsToClaim } = usePendingRewards({
    poolAddress: addressPool,
    currentEpoch,
    isConnected,
    userAddress,
  });
  const [isOnMMProcess, setIsOnMMProcess] = useState(false);
  const [refreshClaimableRewards, setRefreshClaimableRewards] = useState(false);

  const dispatch = useDispatch();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { config: claimConfig } = usePrepareContractWrite({
    address: addressPool,
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    functionName: "multiClaim",
    args: [epochsToClaim],
    enabled: Array.isArray(epochsToClaim) && epochsToClaim.length > 0,
  });

  const {
    data: claimData,
    write: claimWrite,
    isLoading: claimWriteIsLoading,
    error: writeError,
    isError: writeIsError,
    isIdle: writeIsIdle,
    isSuccess: writeIsSuccess,
    status: writeStatus,
    reset: claimWriteReset,
  } = useContractWrite({
    ...claimConfig,
  });

  const {
    data: txData,
    isLoading: waitIsLoading,
    isSuccess: waitIsSuccess,
  } = useWaitForTransaction({
    hash: claimData?.hash,
  });

  useEffect(() => {
    if (writeIsError && isOnMMProcess) {
      setIsOnMMProcess(false);
      HandleRevertMessage(writeError);
    }
  }, [claimWriteReset, isOnMMProcess, writeIsError, writeError]);

  useEffect(() => {
    if (
      claimWrite &&
      !claimWriteIsLoading &&
      isOnMMProcess &&
      !waitIsLoading &&
      !writeIsError
    ) {
      try {
        claimWrite();
      } catch (_) {
        claimWriteReset();
        //silence is gold
      }
    }
  }, [
    claimWriteReset,
    claimWrite,
    claimWriteIsLoading,
    isOnMMProcess,
    waitIsLoading,
    writeIsError,
  ]);

  useEffect(() => {
    if (refreshClaimableRewards) {
      //give 3 seconds after refresh, waiting subgraph saves the event
      setTimeout(handleRefreshClaimableRewards, 3000);
      NotificationManager.success(
        "Rewards claimed successfully",
        "Done!",
        process.env.NOTIFICATION_TIME
      );
    }
  }, [refreshClaimableRewards]);

  //handles
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsOnMMProcess(true);
  };

  const handleTxSuccess = () => {
    setRefreshClaimableRewards(true);
    setIsOnMMProcess(false);
    claimWriteReset();
  };

  const handleRefreshClaimableRewards = () => {
    dispatch(refresh({ name: "allClaimableRewards", value: true }));
    setRefreshClaimableRewards(false);
  };

  //ifs
  if (
    !waitIsLoading &&
    waitIsSuccess &&
    isOnMMProcess &&
    writeStatus === "success" &&
    writeIsSuccess &&
    txData.status === 1
  ) {
    handleTxSuccess();
  }

  if (
    !epochsToClaim ||
    (Array.isArray(epochsToClaim) && epochsToClaim.length === 0)
  ) {
    return;
  }

  if (chain.id.toString() !== process.env.REACT_APP_CHAIN_ID) {
    return (
      <div className="row text-center mt-2">
        <div className="col-12 center">
          <WaveButton
            text="Change Network to claim"
            customLinkClass={customLinkClass}
            customButtonClass={customButtonClass}
            onClick={() =>
              switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
            }
          />
        </div>
      </div>
    );
  }

  if (
    !claimWriteIsLoading &&
    !waitIsLoading &&
    !writeIsSuccess &&
    writeStatus !== "success" &&
    !isOnMMProcess &&
    !waitIsSuccess
  ) {
    if (writeIsError) {
      claimWriteReset();
    }
    return (
      <div className="row text-center mt-2">
        <div className="col-12 center">
          <WaveButton
            id="button-claim"
            customLinkClass={customLinkClass}
            customButtonClass={customButtonClass}
            onClick={handleSubmit}
            text={text}
          />
        </div>
      </div>
    );
  }
};

export default ClaimButton;
