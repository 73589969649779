async function fetchWithCache(input, responseKey) {

    const cacheKey = 'fetch_cache_lm_'
    const cacheDuration = 259200 // 3 days
    const now = parseInt(new Date().getTime() / 1000)

    const readCache = (input, responseKey) => {
        const key = cacheKey + (responseKey ? responseKey + '|'+input : input)
        let stored = localStorage.getItem(key)
        if (!stored) {
            return null
        }
        stored = JSON.parse(stored)
        if (!stored || stored['time'] + cacheDuration < now) {
            return null
        }
        return stored['data']
    }

    const saveCache = (input, responseKey, res) => {
        const key = cacheKey + (responseKey ? responseKey + '|'+input : input)
        const stored = {
            time: now,
            data: responseKey ? res[responseKey] : res
        }
        localStorage.setItem(key, JSON.stringify(stored))
    }
    const cached = readCache(input, responseKey)
    if (cached) {
        return cached
    }
    const response = await (await fetch(input)).json()
    saveCache(input, responseKey, response)
    return responseKey ? response[responseKey] : response

}
export default fetchWithCache;