import { NavLink } from "react-router-dom";
import { useContractReads } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
// import { usePoolValidator } from "../../services/use-pool-validator";
import ClaimButton from "../buttons/claim-button";
import ClaimableRewards from "../claimable-rewards/claimable-rewards";
import DateComponent from "../basics/date-component";
import EpochCertifVol from "../epoch-certif-vol/epoch-certif-vol";
import TotalWalletCertifVol from "../epoch-certif-vol/total-wallet-certif-vol";
import ExchangeName from "../exchange/exchange-name";
import TokenSymbol from "../token-symbol/token-symbol";
import TokenLogo from "../token-logo/token-logo";
import exchangeList from "../../assets/json/exchanges-list.json";
import PoolBoxLoader from "../loaders/pool-box-loader";
import "./pool-box.scss";
import RewardToken from "../reward-token/reward-token";
import ZeroUSD from "../basics/zero-usd";
import AllRewards from '../all-rewards/all-rewards';

const PoolBox = ({
  addressPool,
  addressTokenA,
  addressTokenB,
  chainId,
  endEpochDate,
  exchangeName,
  userAddress,
  isPinnedPool,
}) => {
  const lmPoolContract = {
    address: addressPool,
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
  };

  const { data, isLoading } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        functionName: "getCurrentEpoch",
      },
      {
        ...lmPoolContract,
        functionName: "rewardToken",
      },
    ],
    enabled: addressPool,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  });

  if (isLoading || !data[0] || !data[1]) {
    return <PoolBoxLoader />;
  }

  let epochStr = "1";
  let rewardTokenStr = "";
  if (!isLoading && data.length > 0) {
    epochStr = data[0];
    rewardTokenStr = data[1];
  }
  let addRewardTokenA = false;
  let addRewardTokenB = false;
  if (addressTokenA.toLowerCase() === data[1].toLowerCase()) {
    addRewardTokenA = true;
  }
  if (addressTokenB.toLowerCase() === data[1].toLowerCase()) {
    addRewardTokenB = true;
  }

  return (
    <div className={"pool-box" + (isPinnedPool ? " pinned-pool" : "")}>
      <div className="row">
        <div className="col-xl-5 col-12">
          <div className="row full-center">
            <div className="col-sm-2 col-4">
              <div className="row">
                <div className="col-6">
                  <TokenLogo address={addressTokenA} chainId={chainId} />
                </div>
                <div className="col-6">
                  <TokenLogo address={addressTokenB} chainId={chainId} />
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="row">
                <div className="col-12 text-center">
                  <h6 className="pair-name">
                    <TokenSymbol
                      addRewardTokenData={addRewardTokenA}
                      address={addressTokenA}
                      chainId={chainId}
                      noLink={
                        !exchangeList.exchangeTypes.dex.includes(exchangeName)
                      }
                    />{" "}
                    /
                    <TokenSymbol
                      addRewardTokenData={addRewardTokenB}
                      address={addressTokenB}
                      chainId={chainId}
                      noLink={
                        !exchangeList.exchangeTypes.dex.includes(exchangeName)
                      }
                    />
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-4">
              <div className="row">
                <div className="col-12 text-center">
                  <p className="blue-title m-1">Exchange</p>
                </div>
                <div className="col-12 text-center">
                  <div className="cex-name">
                    <ExchangeName
                      addressTokenA={addressTokenA}
                      addressTokenB={addressTokenB}
                      chainId={chainId}
                      exchangeName={exchangeName}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12">
              <div className="row">
                <div className="col-12 text-center align-self-end">
                  <p className="blue-title m-1">Rewards</p>
                </div>
                <div className="col-12 text-center">
                  <h5>
                    {isLoading ? (
                      <>Loading…</>
                    ) : (
                      <AllRewards
                        epoch={epochStr}
                        rewardToken={rewardTokenStr}
                        address={addressPool}
                        chainId={process.env.REACT_APP_CHAIN_ID}
                      />
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-12">
          <div className="row full-center">
            <div className="col-1 d-none d-xl-block">
              <hr className="separator" />
            </div>

            <span className="pt-20 d-xl-none"></span>

            <div className="col-6 col-xl-5">
              <div className="row">
                <div className="col-12 center">
                  <p>Epoch TVC</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {isLoading ? (
                      <>Loading…</>
                    ) : (
                      <EpochCertifVol
                        epoch={epochStr}
                        poolAddress={addressPool}
                      />
                    )}
                  </h6>
                </div>
              </div>
            </div>

            <div className="col-6 col-xl-5">
              <div className="row">
                <div className="col-12 center">
                  <p>End Date</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <div className="badge">
                    {endEpochDate !== "0" ? (
                      <DateComponent timestamp={endEpochDate} />
                    ) : (
                      <span> - </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <span className="pb-20 d-xl-none"></span>

            <div className="col-1 d-none d-xl-block">
              <hr className="separator" />
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-12">
          <div className="row full-center">
            <div className="col-sm-4 col-6">
              <div className="row">
                <div className="col-12 center">
                  <p>Your Certif. Vol</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {isLoading ? (
                      <>Loading…</>
                    ) : userAddress ? (
                      <TotalWalletCertifVol
                        userAddress={userAddress}
                        poolAddress={addressPool}
                      />
                    ) : (
                      <ZeroUSD />
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-6">
              <div className="row">
                <div className="col-12 center">
                  <p>Claim</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {userAddress ? (
                      isLoading ? (
                        <>Loading…</>
                      ) : (
                        <ClaimableRewards
                          poolAddress={addressPool}
                          userAddress={userAddress}
                          currentEpoch={epochStr}
                          rewardToken={rewardTokenStr}
                        />
                      )
                    ) : (
                      <RewardToken amount={0} address={rewardTokenStr} />
                    )}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-12">
              <div className="row text-center">
                <div className="col-12">
                  <span className="pt-20 d-xl-none"></span>
                  <NavLink className="stats-link" to={"/pool/" + addressPool}>
                    <button className="pool-box-button button-120">
                      Go to Pool
                    </button>
                  </NavLink>
                  <span className="pb-20 d-xl-none"></span>
                </div>
              </div>
              {userAddress && (
                <ClaimButton
                  text="Claim"
                  customButtonClass={"pool-box-button button-100"}
                  currentEpoch={parseInt(epochStr)}
                  addressPool={addressPool}
                  isConnected={!!userAddress}
                  userAddress={userAddress}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolBox;
