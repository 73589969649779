import { useState } from "react";
import AsyncSelect from "react-select/async";
import fetchWithCache from "../../services/fetch-with.cache";
import debounce from "lodash.debounce";
import * as Layer1ToLayer2Names from "../../assets/json/coingecko-layer1-names-to-layer2-names.json";
import * as TokensAddressesOverride from "../../assets/json/tokens-addresses-override.json";
import * as tokenLogosJson from "../../assets/json/token-logos.json";
import "./token-select.scss";

const tokenLogos = tokenLogosJson;

const TokenSelect = ({
  handleTokenAddresses,
  placeholder,
  defaultValue
}) => {
  const [lastCriteriaSearched, setLastCriteriaSearched] = useState("");

  const handleNoOptionsMessage = (inputValue) => {
    if (inputValue.inputValue === "") {
      if (lastCriteriaSearched === "") {
        return "Must type a token";
      } else {
        return "Click on [X] to restart the search ";
      }
    } else {
      return "Token not found";
    }
  };

  const handleAsynSelectTokenChange = async (event) => {
    if (!event || event.value === "") {
      setLastCriteriaSearched("");
      handleTokenAddresses(null);
      return;
    }

    const lastOptionSelected = {label: event.label, value: event.value};

    const tokenCoingeckoName = (Object.keys(Layer1ToLayer2Names)
      .findIndex((e) => (e === event.value)) !== -1)
        ? Layer1ToLayer2Names[event.value]
        : event.value;

    setLastCriteriaSearched(tokenCoingeckoName);
    let tokenAddresses = await fetchWithCache(
      `https://api.coingecko.com/api/v3/coins/${tokenCoingeckoName}`,
      "platforms"
    );
    console.log({tokenAddresses});
    if (TokensAddressesOverride[tokenCoingeckoName]) {
      tokenAddresses = {
        ...tokenAddresses,
        ...TokensAddressesOverride[tokenCoingeckoName]
      }
    }
    handleTokenAddresses(tokenAddresses, lastOptionSelected);
  };

  const _GetOptions = (input, callback) => {
    if (input !== "") {
      fetchWithCache(
        `https://api.coingecko.com/api/v3/search?query=${input}`,
        "coins"
      ).then((data) => {
        const options = data.map((coin) => {
          const coinId = coin.id.toLowerCase();
          const coinThumb = tokenLogos.coingeckoNames[coin.id] 
          ? (window.location.origin + tokenLogos.coingeckoNames[coin.id])
          : coin.thumb;
          return {
            value: coinId,
            label: (
              <div>
                <img
                  src={coinThumb}
                  alt="coin-logo"
                  height="20px"
                  width="20px"
                />{" "}
                {coin.name}
              </div>
            ),
          };
        });
        callback(options);
      });
    }
  };
  const GetOptions = debounce(_GetOptions, 1000);

  const DropdownIndicator = (_) => <></>;
  return (
    <AsyncSelect
      escapeClearsValue
      defaultValue={(JSON.stringify(defaultValue) === "{}") ? null : defaultValue }
      isClearable
      components={{DropdownIndicator}}
      className="selector"
      classNamePrefix="selector"
      placeholder={placeholder}
      cacheOptions
      loadOptions={GetOptions}
      onChange={(event) => handleAsynSelectTokenChange(event)}
      noOptionsMessage={(event) => handleNoOptionsMessage(event)}
    />
   )
}

export default TokenSelect;
