import { NavLink } from "react-router-dom";
import { useContractRead } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import DateComponent from "../basics/date-component";
import EpochRewards from "../epoch-rewards/epoch-rewards";
import ExchangeName from "../exchange/exchange-name";
import TokenLogo from "../token-logo/token-logo";
import TokenSymbol from "../token-symbol/token-symbol";

const PoolRelated = ({
  poolAddress,
  addressTokenA,
  addressTokenB,
  chainId,
  exchange,
  rewardToken,
  endRewardsDate,
}) => {
  const { data, isLoading: isLoadingEpoch } = useContractRead({
    address: poolAddress,
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    functionName: "getCurrentEpoch",
    enabled: poolAddress,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  });

  return (
    <NavLink className="stats-link" to={"/pool/" + poolAddress}>
      <div className="box main_border mt-3">
        <div className="row">
          <div className="col-md-7 col-sm-12 text-center pb-2">
            <TokenLogo
              customClass="pool-detail-img"
              address={addressTokenA}
              chainId={chainId}
            />
            <TokenLogo
              customClass="pool-detail-img right-img"
              address={addressTokenB}
              chainId={chainId}
            />
            <span className="token-symbol-txt">
              <TokenSymbol
                noLink={true}
                addRewardTokenData={addressTokenA === rewardToken}
                address={addressTokenA}
                chainId={chainId}
              />{" "}
              /{" "}
              <TokenSymbol
                noFirstSpace={true}
                noLink={true}
                addRewardTokenData={addressTokenB === rewardToken}
                address={addressTokenB}
                chainId={chainId}
              />
            </span>
          </div>
          <div className="col-md-5 col-sm-12 text-center pb-2">
            <ExchangeName
              showExchangeName={false}
              addressTokenA={addressTokenA}
              addressTokenB={addressTokenB}
              chainId={chainId}
              exchangeName={exchange}
              height={30}
              width={30}
              noLink={true}
            />
          </div>
        </div>

        <hr />

        <div className="row">
          <div className="col-12 text-center align-self-end">
            <p className="blue-title rewards-title m-1">Active Rewards</p>
          </div>
          <div className="col-12 text-center">
            <h5>
              {isLoadingEpoch ? (
                <>Loading…</>
              ) : (
                <EpochRewards
                  epoch={data.toString()}
                  rewardToken={rewardToken}
                  address={poolAddress}
                  chainId={process.env.REACT_APP_CHAIN_ID}
                  noLink={true}
                />
              )}
            </h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12 center">
            <span className="end-date-title">End Date</span>
          </div>
        </div>
        <div className="row">
          <div className="col-12 center">
            <div className="badge end-date">
              {endRewardsDate !== "0" ? (
                <DateComponent timestamp={endRewardsDate} />
              ) : (
                <span> - </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default PoolRelated;
