import {
  erc20ABI,
  useAccount,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite
} from "wagmi";
import faucetTokenABI from "../../assets/abis/faucet.json";
import { useRevertError } from "../../services/use-revert-error";
import { useWriteException } from "../../services/use-write-exception";
import Button from "../buttons/button";
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import "./faucet.scss";
import {Helmet} from "react-helmet";
import { useInvalidNetwork } from "../../services/use-invalid-network";

const Faucet = () => {
  const isInvalidNetwork = useInvalidNetwork();
  const { address, isConnected } = useAccount();

const faucetContract = {
  address: process.env.REACT_APP_TESTNET_FAUCET_ADDRESS,
  abi: erc20ABI,
  chainId: Number(process.env.REACT_APP_CHAIN_ID)
};
  let { data, isLoading: isLoadingRead } = useContractReads({
    contracts: [
      {
        ...faucetContract,
        functionName: "decimals",
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
      },{
        ...faucetContract,
        functionName: "balanceOf",
        args: [address],
      }
    ],
    enabled: !isInvalidNetwork
  });

  const { config, error } = usePrepareContractWrite({
    ...faucetContract,
    abi: faucetTokenABI,
    functionName: "showMeTheMoney",
    enabled: !isInvalidNetwork,
    chainId: Number(process.env.REACT_APP_CHAIN_ID)
  });

  const {
    write: getTokens,
    isLoading,
    error: writeError,
  } = useContractWrite({
    ...config,
  });
  let errorMessage = useRevertError(error);
  let writeErrorMessage = useWriteException(writeError);


  if(isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork}/>
  }

  if (!isConnected) {
    return (
      <div className="center">  
        <Helmet>
          <title>TST Token Faucet | zkMakers</title>
        </Helmet>
        <Button type="button-disabled" text="Connect wallet first!" />
      </div>
    );
  }

  if(isLoadingRead) {
    return (
      <>Loading...</>
    )
  }
  const [ decimals, balance ] = data;

  return (
    <div className="d-flex justify-content-center">
      <Helmet>
        <title>TST Token Faucet | zkMakers</title>
      </Helmet>
      <div className="dark-box create-pool-box">
        <div className="row">
          <div className="col-12">
            <h5>Token Faucet</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-12 justify-center">
            Token Balance: {(balance.toString() / 10 ** decimals).toFixed(2)}TST
          </div>
        </div>

        <hr />

        {writeError && (
          <div className="row mt-3">
            <div className="col-12">{writeErrorMessage}</div>
          </div>
        )}

        {error && (
          <div className="row mt-3">
            <div className="col-12">{errorMessage}</div>
          </div>
        )}

        <div className="row mt-3">
          <div className="col-12 end">
            {isLoading && (
              <Button
                disabled={true}
                text="Processing…"
                type="button-disabled"
              />
            )}

            {!isLoading && !error && (
              <Button
                disabled={!getTokens}
                id="button-faucet"
                onClick={() => getTokens?.()}
                text="Get More Free TST Tokens!"
                type="button-light"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faucet;
