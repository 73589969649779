import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "./chart.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const ChartView = ({ chartDataRaw, currentEpoch, startEpoch }) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value, index, ticks) {
            return value + " $";
          },
        },
      },
    },
  };

  const chartLabels = [];

  for (let iEpoch = startEpoch; iEpoch <= currentEpoch; iEpoch++) {
    chartLabels.push("Week " + iEpoch);
  }

  //Prepare chart
  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        data: chartDataRaw,
        borderColor: "rgb(10, 231, 248)",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 180);
          gradient.addColorStop(0, "rgba(10, 231, 248,1)");
          gradient.addColorStop(1, "rgba(10, 231, 248,0)");
          return gradient;
        },
        fill: true,
        lineTension: 0.4,
        radius: 2,
        borderWidth: 1,
      },
    ],
  };

  return <Line options={chartOptions} data={chartData} />;
};

export default ChartView;
