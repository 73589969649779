import { gql, useQuery } from "@apollo/client";
import { useState } from "react";

export function useEpochVolumeByPoolId({
    poolAddress, 
    startTimestamp, 
    endTimestamp
}) {
    const [ amountUsdTotal, setAmountUsdTotal ] = useState(); 
    poolAddress = poolAddress.toLowerCase();
    const queryPool = gql`query getPool(
            $lmPoolId: String, 
            $startTimestamp: Int, 
            $endTimestamp: Int
        ) {
        points: pointsMintedEntities(
            where: {
                pool: $lmPoolId,
                created_gte: $startTimestamp,
                created_lte: $endTimestamp
            }
        ) {
            amountUsd
        }
    }`;

    let { loading: isLoading } = useQuery(
        queryPool,
        {
            variables: {
                lmPoolId: poolAddress,
                startTimestamp,
                endTimestamp
            },
            skip: amountUsdTotal !== undefined,
            onCompleted(poolData) {
                let newAmountUsdTotal = 0;

                //eslint-disable-next-line
                poolData.points.map(el => {
                    newAmountUsdTotal += parseFloat(el.amountUsd);
                });
                setAmountUsdTotal(newAmountUsdTotal);
            }
        }
    );

    return {
        data: amountUsdTotal,
        isLoading
    }
}