import { gql, useQuery } from "@apollo/client";
import "./pools-related.scss";
import PoolRelated from "./pool-related";

const queryRaw = gql`
  query getPoolsRelated(
    $poolAddress: String
    $pairTokenA: String
    $pairTokenB: String
    $chainId: Int
    $exchange: String
    $rewardToken: String
  ) {
    poolEntities(
      where: {
        pairTokenA: $pairTokenA
        pairTokenB: $pairTokenB
        chainId: $chainId
        exchange: $exchange
        rewardToken_not: $rewardToken
      }
    ) {
      id
      rewardToken
      created
      endRewardsDate
    }
  }
`;

const PoolsRelated = ({
  poolAddress,
  addressTokenA,
  addressTokenB,
  chainId,
  exchange,
  rewardToken,
}) => {
  let {
    data: pools,
    loading: loadingPoolsRaw,
    error: errorPoolsRaw,
  } = useQuery(queryRaw, {
    variables: {
      poolAddress,
      pairTokenA: addressTokenA,
      pairTokenB: addressTokenB,
      chainId,
      exchange,
      rewardToken: rewardToken.toLowerCase(),
    },
  });

  if (!pools || loadingPoolsRaw || errorPoolsRaw) {
    return (
      <div className="pt-3">
        <div className="row">
          <div className="col-12 text-center">
            <h5>Loading…</h5>
          </div>
        </div>
      </div>
    );
  }

  if (!Array.isArray(pools.poolEntities) || pools.poolEntities.length === 0) {
    return (
      <div className="pt-3">
        <div className="row">
          <div className="col-12 text-center">
            <h5>Similar pools not found</h5>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col-12 text-center pt-3">
          <h5>Similar pools</h5>
        </div>
      </div>
      <div className="row">
        {pools.poolEntities.map((pool) => {
          return (
            <div className="col-sm-12 col-lg-6" key={pool.id}>
              <PoolRelated
                addressTokenA={addressTokenA}
                addressTokenB={addressTokenB}
                chainId={chainId}
                exchange={exchange}
                poolAddress={pool.id}
                rewardToken={pool.rewardToken}
                endRewardsDate={pool.endRewardsDate}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PoolsRelated;
