import { createSlice } from '@reduxjs/toolkit';
import filters from "../assets/json/filters.json";

const firstSearch = {
    tokenAddressesA: null,
    tokenAddressesB: null,
    lastOptionSelectedA: null,
    lastOptionSelectedB: null,
    exchange: filters.exchangeFiltersList[0],
    poolType: filters.poolTypeFiltersList[0],
    state: filters.stateFiltersListConst[0],
    sorting: filters.sortingFiltersList[0]
};
export const searchSlice = createSlice({
    name: 'search',
    initialState: {
        last: firstSearch
    },
    reducers: {
        addLastSearch: (state, action) => {
            state.last = {
                tokenAddressesA: action.payload.tokenAddressesA,
                tokenAddressesB: action.payload.tokenAddressesB,
                lastOptionSelectedA: action.payload.lastOptionSelectedA,
                lastOptionSelectedB: action.payload.lastOptionSelectedB,
                exchange: action.payload.exchange,
                poolType: action.payload.poolType,
                state: action.payload.state,
                sorting: action.payload.sorting
            };
        }
    }
});

export const { addLastSearch } = searchSlice.actions;
const searchSliceReducers = searchSlice.reducer;
export default searchSliceReducers;
