import { gql, useQuery } from "@apollo/client";
import { memo } from "react";
import { default as exchangeList } from "../../assets/json/exchanges-list.json";
import { default as networksListByExchange } from "../../assets/json/networks-list-by-exchange.json";
import { abreviateAddress } from "../../services/use-abreviate-address";
import DateComponent from "../basics/date-component";
import ChartLoader from "../loaders/chart-loader";
import ChartView from "./chart-view";

const query = gql`
  query getRewardsByPoolByEpoch(
    $poolAddress: String
    $startEpoch: Int
    $currentEpoch: Int
  ) {
    poolEntity(id: $poolAddress) {
      exchange
      rewardsByPool(
        where: { epoch_gte: $startEpoch, epoch_lte: $currentEpoch }
      ) {
        epoch
        amountUsd
      }
    }
  }
`;

const ChartSCData = ({
  poolAddress,
  addressTokenA,
  addressTokenB,
  rewardToken,
  currentEpochStartDate,
  chainId,
  currentEpoch,
  startEpoch,
}) => {
  const { chainId: rpcUrl } = JSON.parse(
    process.env.REACT_APP_BLOCK_EXPLORER_URLS
  );

  //Request the data on subgraph
  const {
    data: scDataRaw,
    loading: scDataLoading,
    error: scDataError,
  } = useQuery(query, {
    variables: {
      poolAddress,
      startEpoch,
      currentEpoch,
    },
    fetchPolicy: "no-cache",
  });

  //If the data is loading show a loader
  if (
    scDataLoading ||
    scDataError ||
    !scDataRaw ||
    typeof scDataRaw !== "object" ||
    !scDataRaw.poolEntity ||
    typeof scDataRaw.poolEntity !== "object" ||
    !scDataRaw.poolEntity.rewardsByPool ||
    !Array.isArray(scDataRaw.poolEntity.rewardsByPool)
  ) {
    return <ChartLoader />;
  }

  const amountUsdPerEpoch = new Array(currentEpoch + 1).fill(0);
  scDataRaw.poolEntity.rewardsByPool.map(
    (el) => (amountUsdPerEpoch[parseInt(el.epoch)] += parseFloat(el.amountUsd))
  );

  //Define constants and vars for the chart
  const scData = amountUsdPerEpoch.filter((_, index) => index >= startEpoch);

  currentEpochStartDate = parseInt(currentEpochStartDate.toString());
  currentEpochStartDate = currentEpochStartDate + (currentEpoch - 1) * 604800;
  const currentEpochEndDate = currentEpochStartDate + 604800 + 1;

  const blockchainTitle = networksListByExchange[
    scDataRaw.poolEntity.exchange
  ].filter((el) => el.key === chainId.toString())[0]?.title;

  return (
    <>
      {exchangeList.exchangeTypes.dex.includes(
        scDataRaw.poolEntity.exchange
      ) && (
        <>
          <div className="row">
            <div className="col-4 epoch-tag">Blockchain Tracked:</div>
            <div className="col-8">{blockchainTitle}</div>
          </div>

          <div className="row">
            <div className="col-4 epoch-tag">SC Address:</div>
            <div className="col-8">
              <a
                className="link white"
                target="_blank"
                href={rpcUrl[process.env.REACT_APP_CHAIN_ID] + poolAddress}
              >
                {abreviateAddress(poolAddress, 10)}
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-4 epoch-tag">Token A Address:</div>
            <div className="col-8">
              <a
                className="link white"
                target="_blank"
                href={rpcUrl[chainId] + addressTokenA}
              >
                {abreviateAddress(addressTokenA, 10)}
              </a>
            </div>
          </div>

          <div className="row">
            <div className="col-4 epoch-tag">Token B Address:</div>
            <div className="col-8">
              <a
                className="link white"
                target="_blank"
                href={rpcUrl[chainId] + addressTokenB}
              >
                {abreviateAddress(addressTokenB, 10)}
              </a>
            </div>
          </div>
        </>
      )}

      <div className="row">
        <div className="col-4 epoch-tag">Reward Token Address:</div>
        <div className="col-8">
          <a
            className="link white"
            target="_blank"
            href={rpcUrl[process.env.REACT_APP_CHAIN_ID] + rewardToken}
          >
            {abreviateAddress(rewardToken, 10)}
          </a>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-4 epoch-tag">Current Week:</div>
        <div className="col-8">
          <DateComponent timestamp={currentEpochStartDate} /> -{" "}
          <DateComponent timestamp={currentEpochEndDate} />
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-12">
          <ChartView
            startEpoch={startEpoch}
            currentEpoch={currentEpoch}
            chartDataRaw={scData}
          />
        </div>
      </div>
    </>
  );
};

export default memo(ChartSCData);
