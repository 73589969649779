import RewardTokensInfo from "../assets/json/reward-tokens-info.json";

export function getRewardCurrencyItemsChild({
    rewardCurrencyItems,
    pairChainId
}){
    let rewardCurrencyItemsChild = rewardCurrencyItems;

    if(pairChainId === parseInt(process.env.REACT_APP_CHAIN_ID)) {
        let rewardCurrencyItemsExtra = [
                {
                key: RewardTokensInfo.addressZeroA,
                title: RewardTokensInfo.tokenARewardToken,
                decimals: 0,
                address: RewardTokensInfo.addressZeroA
            },{
                key: RewardTokensInfo.addressZeroB,
                title: RewardTokensInfo.tokenBRewardToken,
                decimals: 0,
                address: RewardTokensInfo.addressZeroB
            }
        ];
        rewardCurrencyItemsChild = [
            ...rewardCurrencyItems,
            ...rewardCurrencyItemsExtra
        ];
    }

    return rewardCurrencyItemsChild;
}

export function getRewardTokenDefault({
    failAreAllERC20,
    pairTokenA,
    pairTokenB,
    rewardToken,
    rewardCurrencyItemsChild
}) {

    if(typeof rewardToken === 'string') {
        rewardToken = rewardToken.toLowerCase();
    }
    let rewardTokenDefault = "";
    
    const rewardTokensValid = rewardCurrencyItemsChild.map(el => el.key);
    const iRewardToken = rewardTokensValid.findIndex(el => el === rewardToken);

    if(iRewardToken !== -1) {
        rewardTokenDefault = rewardTokensValid[iRewardToken];
    }else {
        if(
            typeof pairTokenA === 'string'
            &&
            pairTokenA !== ""
            &&
            rewardToken === pairTokenA.toLowerCase()
        ) {
            rewardTokenDefault = failAreAllERC20 !== 0 ? RewardTokensInfo.addressZeroA : "";
        }
        if(
            typeof pairTokenB === 'string'
            &&
            pairTokenB !== ""
            &&
            rewardToken === pairTokenB.toLowerCase()
        ) {
            rewardTokenDefault = failAreAllERC20 !== 1 ? RewardTokensInfo.addressZeroB : "";
        }
    }
    return rewardTokenDefault;
}
