import PoolCounter from "../pool-counter/pool-counter";
import { useEpochVolume } from "../../services/use-epoch-volume";
import { useWeeklyRewards } from "../../services/use-weekly-rewards";
import "./platform-box.scss";
import DollarComponent from "../basics/dollar-component";

const PlatformBox = () => {
  const { 
    data: epochTVCPlatform, 
    isLoading: isLoadingEpochTVCPlatform 
  } = useEpochVolume({poolType: 1});
  
  const { 
    data: weeklyRewards, 
    isLoading: isLoadingWeeklyRewards 
  } = useWeeklyRewards();

  return (
    <div className="box">
      <div className="row">
        <div className="col-12 pb-10">
          <h5>Platform</h5>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">
                Weekly TVC{" "}
                <img
                  className="info-icon"
                  src="/assets/img/info-icon.png"
                  alt="info icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Total volume certified this week."
                />
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <h5>
              {isLoadingEpochTVCPlatform
                ? <>Loading…</>
                : <DollarComponent amount={epochTVCPlatform} />
              }
              </h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">Active Pools</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <h5>
                <PoolCounter />
              </h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col-12">
              <p className="title">Active Rewards</p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <h5>
                {isLoadingWeeklyRewards
                  ? <>Loading…</>
                  : <DollarComponent amount={weeklyRewards} />
                }
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformBox;
