import ReactDOM from "react-dom";
import Button from "../buttons/button";
import "../modal/modal.scss";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";

const CreateRewardPoolModal = ({
  show,
  save,
  cancel,
  children,
  isConnected,
  isCheckingAddresses
}) => {
  const title="Create a token pair";
  const saveTextButton="Save and continue";
  const cancelTextButton="Cancel";

  return ReactDOM.createPortal(
    <>
      {show ? (
        <div className="lm_modalContainer" onClick={() => cancel()}>
          <div className="lm_modal" onClick={(e) => e.stopPropagation()}>
            <div className="lm_modal_header">
              <h2 className="lm_modal_header_title">{title}</h2>
            </div>
            {isConnected 
              ? (
                <>
                  <div className="lm_modal_body">{children}</div>
                  <div className="lm_modal_footer">
                    {!isCheckingAddresses
                      ? (
                        <>
                          <Button
                            text={saveTextButton}
                            type="button-light"
                            onClick={save}
                          />
                          <Button
                            text={cancelTextButton}
                            type="button-light mr-20"
                            onClick={cancel}
                          />
                        </>
                      )
                      : (
                        <Button
                          text="Reading addresses data…"
                          type="button-disabled"
                        />
                      )
                  }
                  </div>
                </>
              ) : (
                <ConnectYourWalletFirst isModal={true} closeFN={cancel}/>
              )}
          </div>
        </div>
      ) : null}
    </>,
    document.getElementById("modal")
  );
};

export default CreateRewardPoolModal;
