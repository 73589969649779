import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { refresh } from "../../features/refresh-slice";

const allPoolsQuery = gql`
  query getActivePools(
    $now: Int,
    $limit: Int, 
    $offset: Int
  ) {
    rewardByPoolEntities(
      first: $limit, 
      skip: $offset,
      where: {
        endEpochDate_gte: $now, 
        startEpochDate_lte: $now
      }
    ) {
      pool {
        id
      }
    }
  }`;

const getPools = async (client, refreshValue) => {
  const limit = 1000;
  const now = parseInt(Date.now() / 1000);
  let total = 0;
  let numLastReaded = 0;
  let i = 0;

  const countPools = (rows => {
    const { data } = rows;
    const {rewardByPoolEntities: items } = data;
    let arrPools = [];
    items.map(el => {
        const flagSave = arrPools.findIndex(savedPool => el.pool.id === savedPool);
        if(flagSave === -1){
          arrPools.push(el.pool.id);
        }
    });
    return arrPools.length;
  });
  
  do{
    const result = await client.query({
      query: allPoolsQuery,
      variables: {
        now,
        limit,
        offset: limit * i,
      },
      fetchPolicy: refreshValue ? 'network-only' : 'cache-first'
    });
   
    if(!result) {
      break;
    }
    numLastReaded = countPools(result);
    total += numLastReaded;
    i++;
  }while(numLastReaded === limit);
  return total;
};

const usePoolCount = () => {
  
  const [poolsCounter, setPoolCounter] = useState(null);
  const refreshValue = useSelector(state => state.refresh.poolCounter);
  const dispatch = useDispatch();

  const client = useApolloClient();
  if(poolsCounter === null) {
    getPools(client, refreshValue)
    .then((data) => {
      setPoolCounter(data);
    })
    .catch((_) => {
      setPoolCounter(0);

    })
    .finally(()=>{
      dispatch(refresh({name: 'poolCounter', value: false}));
    });
  }
    
  return { data: poolsCounter };
};

const PoolCounter = () => {
  const { data } = usePoolCount();
  if (data === undefined || data === null) {
    return <>Loading...</>;
  }
  return data;
};

export default PoolCounter;
