import { memo } from "react";
import { parseZKMFloat } from '../../services/use-parse-zkm-float';

const DollarComponent = ({amount}) => {
    return (
        <>
            <span className="dollar">$</span> 
            {parseZKMFloat(amount)}
        </>
    )
}
export default memo(DollarComponent);
