import { useState } from "react";
import ChartSCData from "./chart-sc-data";
import ChartTVC from "./chart-tvc";
import ChartAPY from "./chart-apy";
import "./chart.scss";

const Chart = ({
  currentEpoch,
  poolAddress,
  addressTokenA,
  addressTokenB,
  chainId,
  rewardToken,
  currentEpochStartDate,
}) => {
  const [tabActive, setTabActive] = useState(0);
  // const chartTitles = [
  //   "USD",
  //   "USD",
  //   "Historical rate (in %)"
  // ];

  currentEpoch = parseInt(currentEpoch);
  const startEpoch = Math.max(currentEpoch - 6, 1);

  return (
    <>
      <div className="box main_border">
        <div className="row justify-end pb-5">
          <div className="col-4 col-md-3">
            <button
              className={
                tabActive === 0 ? "chart-selector selected" : "chart-selector"
              }
              onClick={() => setTabActive(0)}
            >
              SC Data
            </button>
          </div>
          <div className="col-4 col-md-2">
            <button
              className={
                tabActive === 1 ? "chart-selector selected" : "chart-selector"
              }
              onClick={() => setTabActive(1)}
            >
              TVC
            </button>
          </div>
          <div className="col-4 col-md-2">
            <button
              className={
                tabActive === 2 ? "chart-selector selected" : "chart-selector"
              }
              onClick={() => setTabActive(2)}
            >
              APY
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12">
            {tabActive === 0 && (
              <ChartSCData
                poolAddress={poolAddress}
                addressTokenA={addressTokenA}
                addressTokenB={addressTokenB}
                rewardToken={rewardToken}
                chainId={chainId}
                startEpoch={startEpoch}
                currentEpoch={currentEpoch + 3}
                currentEpochStartDate={currentEpochStartDate}
              />
            )}
            {tabActive === 1 && (
              <ChartTVC
                poolAddress={poolAddress}
                startEpoch={startEpoch}
                currentEpoch={currentEpoch}
              />
            )}
            {tabActive === 2 && (
              <ChartAPY
                poolAddress={poolAddress}
                startEpoch={startEpoch}
                currentEpoch={currentEpoch}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
