import { createSlice } from '@reduxjs/toolkit';

export const txSlice = createSlice({
    name: 'tx',
    initialState: {
        last: {
            hash: ''
        }
    },
    reducers: {
        saveLastTx: (state, action) => {
            state.last = {
                hash: action.payload,
            };
        }
    }
});

export const { saveLastTx } = txSlice.actions;
const txSliceReducers = txSlice.reducer;
export default txSliceReducers;
