import { useState } from "react";
import { gql, useQuery, useApolloClient } from "@apollo/client";
import { constants } from "ethers";
import filterAddressList from "../../services/filter-address-list";
import AllPoolsView from "./all-pools-view";
import AllPoolsViewEmpty from "./all-pools-view-empty";
import AllPoolsViewLoader from "../loaders/all-pools-view-loader";
import AllPinnedPools from "../all-pools/all-pinned-pools";

const numResultsPerPage = 10;
const queryCountRaw = `query getPoolEntities(
    $limit: Int, 
    $offset: Int,
    $timestamp: Int
  ) {
  poolEntities(
    first: $limit,
    skip: $offset,
    where: {ALL_CONDITIONS}
  ) {
    id
    created
    rewardsByPool(
      first: 1
      where: {
        endEpochDate_gte: $timestamp, 
        startEpochDate_lte: $timestamp
      }
    ) {
      id
    }
  }
}`;

const getPools = async ({
  client,
  conditionsIn,
  conditionsOut,
  conditionState,
  setAllPoolsResponse,
  timestamp,
}) => {
  const limit = 1000;
  const conditions = [conditionsIn, conditionsOut];
  let numLastReaded = 0;
  let allPools = {};

  for (let iCondition = 0; iCondition < conditions.length; iCondition++) {
    if (conditions[iCondition].length > 0) {
      let i = 0;
      const queryCount = queryCountRaw.replace(
        "ALL_CONDITIONS",
        conditions[iCondition].join(", ")
      );
      do {
        const { data } = await client.query({
          query: gql`
            ${queryCount}
          `,
          variables: {
            limit: limit,
            offset: limit * i,
            timestamp,
          },
        });
        // .catch(_ => console.log(_));

        numLastReaded = data?.poolEntities.length;
        data.poolEntities.map((pool) => {
          const numRewardsByPoolEntities = pool.rewardsByPool.length;

          if (
            conditionState === "all" ||
            conditionState === "poolsCreatedByMe" ||
            conditionState === "participatedInPools" ||
            (numRewardsByPoolEntities > 0 && conditionState === "opened") ||
            (numRewardsByPoolEntities === 0 && conditionState === "closed")
          ) {
            let created = pool.created;
            while (allPools[created]) {
              created++;
            }
            allPools[created] = pool.id;
          }
        });
        i++;
      } while (numLastReaded === limit);
    }
  }
  setAllPoolsResponse(allPools);
};

const usePoolCount = (
  conditionsIn,
  conditionsOut,
  conditionState,
  setAllPoolsResponse,
  flagGetPools,
  timestamp
) => {
  const client = useApolloClient();
  if (flagGetPools) {
    getPools({
      client,
      conditionsIn,
      conditionsOut,
      conditionState,
      setAllPoolsResponse,
      timestamp,
    }).catch((_) => {
      // console.log(_);
      // nothing more to say
    });
  }
};

const AllPools = ({
  tokenAddressesA,
  tokenAddressesB,
  exchange,
  poolType,
  state,
  sorting,
  timestamp,
  userAddress,
}) => {
  let stateTypes = ["opened", "closed", "all"];
  stateTypes[3] = userAddress ? "poolsCreatedByMe" : undefined;

  const [allPoolsResponse, setAllPoolsResponse] = useState(null);

  const conditionsArrGeneral = [];
  let conditionsArrIn = [];
  let conditionsArrOut = [];

  //CONSTRUCT CONDITION FILTERS
  if (exchange !== "all") {
    conditionsArrGeneral.push('exchange: "' + exchange + '"');
  }

  if (poolType !== "all") {
    conditionsArrGeneral.push('poolType: "' + poolType + '"');
  }

  if (stateTypes.includes(state)) {
    conditionsArrGeneral.push(
      state === "poolsCreatedByMe" ? 'creator: "' + userAddress + '"' : ""
    );
  }

  let { data: myPoolIds, loading: participatedInPoolsLoading } = useQuery(
    gql`
      query getParticipatedInPools($userAddress: String) {
        pointsMintedEntities(where: { user: $userAddress }) {
          pool {
            id
          }
        }
      }
    `,
    {
      variables: {
        userAddress,
      },
      skip: !userAddress || state !== "participatedInPools",
    }
  );

  if (!participatedInPoolsLoading && myPoolIds) {
    const ids = {};
    myPoolIds.pointsMintedEntities.map((el) => (ids[el.pool.id] = null));

    conditionsArrGeneral.push(
      'id_in: ["' +
        (Object.keys(ids).length > 0
          ? Object.keys(ids).join('", "')
          : constants.AddressZero) +
        '"]'
    );
  }

  if (Array.isArray(tokenAddressesA)) {
    tokenAddressesA = filterAddressList(tokenAddressesA);
    if (tokenAddressesA.length > 0) {
      conditionsArrIn.push(
        'pairTokenA_in: ["' + tokenAddressesA.join('", "') + '"]'
      );
      conditionsArrOut.push(
        'pairTokenB_in: ["' + tokenAddressesA.join('", "') + '"]'
      );
    }
  }

  if (Array.isArray(tokenAddressesB)) {
    tokenAddressesB = filterAddressList(tokenAddressesB);
    if (tokenAddressesB.length > 0) {
      conditionsArrIn.push(
        'pairTokenB_in: ["' + tokenAddressesB.join('", "') + '"]'
      );
      conditionsArrOut.push(
        'pairTokenA_in: ["' + tokenAddressesB.join('", "') + '"]'
      );
    }
  }

  usePoolCount(
    conditionsArrIn.concat(conditionsArrGeneral),
    conditionsArrIn.length > 0 && conditionsArrOut.length > 0
      ? conditionsArrOut.concat(conditionsArrGeneral)
      : conditionsArrOut,
    state,
    setAllPoolsResponse,
    !participatedInPoolsLoading && allPoolsResponse === null,
    timestamp
  );

  return allPoolsResponse !== null ? (
    Object.keys(allPoolsResponse).length > 0 ? (
      <>
        <div>
          <AllPinnedPools userAddress={userAddress} />
        </div>
        <AllPoolsView
          sorting={sorting}
          numResultsPerPage={numResultsPerPage}
          numPages={Math.floor(
            Object.keys(allPoolsResponse).length / numResultsPerPage
          )}
          numResults={Object.keys(allPoolsResponse).length}
          dbPoolsIds={Object.values(allPoolsResponse)}
          userAddress={userAddress}
          timestamp={timestamp}
        />
      </>
    ) : (
      <AllPoolsViewEmpty />
    )
  ) : (
    <AllPoolsViewLoader />
  );
};

export default AllPools;
