import { useRevertError } from "../../services/use-revert-error";
import { useWriteException } from "../../services/use-write-exception";
import { NotificationManager } from 'react-notifications';

export const HandleRevertMessage = (message) => {
  NotificationManager.error(useRevertError(message), 'Error!', process.env.NOTIFICATION_TIME);
}

export const HandleWriteExceptionMessage = (message) => {
  NotificationManager.error(useWriteException(message), 'Error!', process.env.NOTIFICATION_TIME);
}
