import { useAccount, useContractReads } from 'wagmi';
import PoolABI from "../assets/abis/pool.json";

export function usePendingRewards({
  poolAddress, 
  currentEpoch,
  isConnected
}) {
  const { address: userAddress } = useAccount();

    const lmPoolContract = {
      address: poolAddress,
      abi: PoolABI,
      chainId: Number(process.env.REACT_APP_CHAIN_ID),
      enabled: isConnected,
    };

    let claimReads = [];
  
    const currentEpochsPeriod = [];
    for (
      let iEpoch = currentEpoch - 1;
      iEpoch >= (currentEpoch - 1 > 5 ? currentEpoch - 3 : 1);
      iEpoch--
    ) {
      claimReads.push({
        ...lmPoolContract,
        functionName: "pendingReward",
        args: [userAddress, iEpoch]
      });
      currentEpochsPeriod.push(iEpoch);
    }
  
    const { data: pendingEpochsToClaim } = useContractReads({
      contracts: claimReads,
      enabled: isConnected,
      watch: true
    });

    if (
      !pendingEpochsToClaim 
      ||
      (
        pendingEpochsToClaim
        && 
        pendingEpochsToClaim[0] === null
      )
    ) {
      return {epochsToClaim: []}
    }
  
    let epochsToClaim = [];
    pendingEpochsToClaim.map((rewardBN, iEpochToClaim) => {
      if (rewardBN.toString() !== '0') {
        epochsToClaim.push(currentEpochsPeriod[iEpochToClaim]);
      }
    });
    return {epochsToClaim}
}
