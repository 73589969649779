import { ethers } from "ethers";

export const abreviateAddress = (address, digits) => {
  address = ethers.utils.getAddress(address);
  return (
    address.substring(0, digits) +
    "…" +
    address.substring(address.length, address.length - digits)
  );
};
