
import { memo } from "react";
import {Helmet} from "react-helmet";

const Page404 = () => {
    return (
        <div className="box">
            <Helmet>
                <title>404. Page not found | zkMakers</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-12 align-center">
                    <div className="col-12 center">
                        <h2 className='white'>404. Page not found</h2>
                    </div>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 align-center">
                    <div className="col-12 center">
                        <h5>The requested page was not found on this server.</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(Page404);