export function useRevertError(writeError) {
    let writeErrorMessage = null;
    if (writeError) {
        writeErrorMessage = writeError.reason;
        if (writeError.code === 'INSUFFICIENT_FUNDS') {
            writeErrorMessage = 'Not enough funds.'
        } else if (writeError.code === 'NETWORK_ERROR') {
            writeErrorMessage = 'Incorrect chain. Check network and reload the page.'
        } else if(writeError.code === 'ACTION_REJECTED' || writeError.code === 4001) {
            writeErrorMessage = 'User rejected the action.'
        } else if(writeError.code === -32603) {
            let writeErrorData;
            try{
                writeErrorData = JSON.parse(writeError.data);
            } catch(_){
                try{
                    if(writeError.stack) {
                        const dataTmpRaw = writeError.stack.split("'{");
                        let dataRaw = "{"+ dataTmpRaw[1].substring(0, dataTmpRaw[1].length - 1);
                        writeErrorData = JSON.parse(dataRaw);
                    }
                }catch(_){}
            } finally {
                if(writeErrorData.code === 3) {
                    writeErrorMessage = "Only account owner can submit proof.";
                }else if(writeErrorData.code === -32000) {
                    writeErrorMessage = "Must increase Gas Limit on your wallet to do this action.";
                }else {
                    writeErrorMessage = "Internal JSON-RPC error.";
                }
            }
        }else if (writeError.reason.indexOf('execution reverted:') !== -1) {
            writeErrorMessage = writeErrorMessage.replace('execution reverted: ', '')
        } 
    }
    return writeErrorMessage;
}
