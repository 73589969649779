import RewardTokenSymbol from "./reward-token-symbol";
import NumberComponent from "../basics/number-component";
import { memo } from "react";
import { ethers } from "ethers";
import { useSelector } from "react-redux";

const RewardToken = ({ address, noLink, amount }) => {
  let rewardTokenAddress = (
    address ? address : process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT
  ).toLowerCase();
  let rewardTokenData = useSelector(
    (state) => state.rewardTokens.all[rewardTokenAddress]
  );
  if (!rewardTokenData) {
    rewardTokenData = {
      decimals: parseInt(process.env.REACT_APP_REWARD_TOKEN_DECIMALS_DEFAULT),
      symbol: '...',
      address: process.env.REACT_APP_REWARD_TOKEN_ADDRESS_DEFAULT,
    };
  }

  return (
    <span>
      <NumberComponent
        num={ethers.utils.formatEther(amount)}
      />{" "}
      <RewardTokenSymbol
        symbol={rewardTokenData.symbol}
        address={rewardTokenData.address}
        noLink={noLink}
      />
    </span>
  );
};

export default memo(RewardToken);
