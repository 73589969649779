import copy from 'copy-to-clipboard';
import { NotificationManager } from 'react-notifications';

const CopyButton = ({text, dataToCopy}) => {

    const copyAction = () => {
        copy(dataToCopy);
        NotificationManager.success(
            "Copied succesfully",
            "Done!",
            process.env.NOTIFICATION_TIME
        );
    }
    return (<button className='button-light' onClick={() => copyAction()}>{text}</button>);
};

export default CopyButton;