import { memo } from "react";

const ChartLoader = ({message}) => {
    if(!message) {
        message = "Loading chart...";
    }

    return (
        <div className="row pt-5">
            <div className="col-12 text-center">
                <h5 className="white center">{message}</h5>
            </div>
        </div>
    )
}
export default memo(ChartLoader);