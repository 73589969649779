import { memo } from "react";

const PoolDetailLoader = ({message}) => {
  if(!message) {
    message = "Loading pool...";
  }
  return (
    <div className="box">
      <div className="row mt-4">
        <div className=" col-12 center">
          <h2 className='white'>{message}</h2>
        </div>
      </div>
    </div>
  )
}
export default memo(PoolDetailLoader);