import { memo } from "react";

const UniswapSwapLoader = () => {
  return (
    <div className='justify-center pt-40'>
      <h2 className='white'>Loading data...</h2>
    </div>
  )
}

export default memo(UniswapSwapLoader);