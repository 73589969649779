export function parseZKMFloat(amount) {
    if(typeof amount === 'string') {
        amount = parseFloat(amount);
    }
    if(amount === 0){
        return '0';
    }

    let unit = '';
    let divisor = 1;
    if (amount > 999999999 || amount < -999999999) {
        unit = 'B';
        divisor = 1000000000;
    } else if (amount > 999999 || amount < -999999 ) {
        unit = 'M';
        divisor = 1000000;
    } else if (amount > 999 || amount < -999) {
        unit = 'k';
        divisor = 1000;
    } 
  
    const formatDecimals = (digit1, digit2) => {
        let responseStr = '';

        if(!digit1 
            || 
            (
                digit1 === '0' 
                && 
                digit2 === '0'
            )
        ) {
            return responseStr;
        }

        responseStr = '.' + digit1;

        if(digit2 && digit2!=='0'){
            responseStr += digit2;
        }      
        return responseStr;
    }
    
    
  	if(divisor > 1) {
        amount = amount / divisor;
        const amountParts = amount.toString().split('.');

        amount = amountParts[0] + (
        amountParts[1] 
            ? formatDecimals(amountParts[1][0], amountParts[1][1]) 
            : ''
        );
    }else{
        amount = amount.toFixed(2);
    }

    return amount + unit;
}
