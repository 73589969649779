import { createSlice } from '@reduxjs/toolkit'
import { ethers } from 'ethers';

export const rewardTokensSlice = createSlice({
    name: 'rewardTokens',
    initialState: {
        default: {},
        all: {}
    },
    reducers: {
        initRewardTokens: (state, action) => {
            // eslint-disable-next-line
            Object.keys(action.payload).map((rewardToken, index) => {
                state.default[rewardToken] = Object.values(action.payload)[index];
                state.all[rewardToken] = Object.values(action.payload)[index];
            });
        },
        addRewardToken: (state, action) => {
            state.all[action.payload.addressLower] = {
                decimals: action.payload.decimals,
                symbol: action.payload.symbol,
                address: ethers.utils.getAddress(action.payload.addressLower)
            }
        }
    }
});

export const { initRewardTokens, addRewardToken } = rewardTokensSlice.actions;
const rewardTokensSliceReducers = rewardTokensSlice.reducer;
export default rewardTokensSliceReducers;
