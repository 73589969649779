import { gql, useQuery } from "@apollo/client";
import ChartLoader from "../loaders/chart-loader";
import ChartView from "./chart-view";
import { memo } from "react";

const query = gql`query getPointsMintedByEpoch(
    $poolAddress: String,
    $startEpoch: Int,
    $currentEpoch: Int
  ) {
    poolEntity(id: $poolAddress) {
      pointsMinted(
        where: {
          epoch_gte: $startEpoch,
          epoch_lte: $currentEpoch
        }
      ) {
        epoch
        amountUsd
      }
    }
  }`;

  
const ChartTVC = ({
    poolAddress,
    currentEpoch,
    startEpoch
}) => {

    //Request the data on subgraph
    const { 
      data: tvcDataRaw,
      loading: tvcDataLoading,
      error: tvcDataError
    } = useQuery(query, {
      variables: {
        poolAddress,
        startEpoch,
        currentEpoch
      },
      fetchPolicy: "no-cache"
    });

    //If the data is loading show a loader
    if(
      tvcDataLoading ||
      tvcDataError ||
      !tvcDataRaw ||
      typeof tvcDataRaw !== 'object' ||
      !tvcDataRaw.poolEntity ||
      typeof tvcDataRaw.poolEntity !== 'object' ||
      !tvcDataRaw.poolEntity.pointsMinted ||
      !Array.isArray(tvcDataRaw.poolEntity.pointsMinted)
    ) {
      return <ChartLoader/>
    }

    const amountUsdPerEpoch = new Array(currentEpoch + 1).fill(0);
    tvcDataRaw.poolEntity.pointsMinted.map(
        el => amountUsdPerEpoch[parseInt(el.epoch)] += parseFloat(el.amountUsd)
    );

    //Define constants and var for the chart
    const tvcData = amountUsdPerEpoch.filter((_,index) => index >= startEpoch);

    return (
        <div className="row pt-4">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-end">
                <p className="chart-title">USD</p>
            </div>
            <div className="col-12">
                <ChartView 
                    startEpoch={startEpoch}
                    currentEpoch={currentEpoch}
                    chartDataRaw={tvcData}
                />
            </div>
        </div>
    )
}

export default memo(ChartTVC);