import RewardToken from "../reward-token/reward-token";
import { useSelector, useDispatch } from "react-redux";
import { refresh } from "../../features/refresh-slice";
import { gql, useQuery } from '@apollo/client';
import { ethers } from "ethers";

const AllRewards = ({ 
  epoch, 
  rewardToken, 
  address, 
  noLink 
}) => {


const query = `query getRewardsByPoolEntities(
  $pool: Bytes,
  $epoch: Int
) {
  rewardByPoolEntities (
      where: {
        pool: $pool,
        epoch_gte: $epoch
      }
  ) {
      amount
      pool {
          rewardToken
      }
  }
}`;

  const keyRendered = useSelector(state => state.refresh.epochRewards);
  const dispatch = useDispatch();

  if(keyRendered) {
    dispatch(refresh({name: 'epochRewards', value: false}))
  }

  const {
    data: rewards,
    loading: isLoading,

  } = useQuery(gql`${query}`, {
    fetchPolicy: "cache-and-network",
    variables: {
      pool: address,
      epoch: epoch ? parseInt(epoch.toString()) : undefined,
    },
  });

  if (isLoading || !rewards || !epoch) {
    return <>Loading…</>
  }
  let finalAmount = ethers.BigNumber.from('0');
  for (const reward of rewards.rewardByPoolEntities) {
    finalAmount = finalAmount.add(reward.amount);
  }

  return (
    <RewardToken 
    amount={finalAmount}
    address={rewardToken}
    noLink={noLink}
  />
  );
};

export default AllRewards;
