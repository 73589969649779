import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useProvider,
  useSigner,
  useWaitForTransaction,
} from "wagmi";
import factoryABI from "../../assets/abis/factory.json";
import { default as exchangeList, default as exchangesList } from "../../assets/json/exchanges-list.json";
import { default as networksListByExchange } from "../../assets/json/networks-list-by-exchange.json";
import { refresh } from "../../features/refresh-slice";
import getAllRewardTokensForSelect from "../../services/get-all-reward-tokens-for-select";
import { useInvalidNetwork } from "../../services/use-invalid-network";
import AddRewards from "../add-rewards/add-rewards";
import Button from "../buttons/button";
import ChainLogo from "../chain-logo/chain-logo";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import { HandleRevertMessage } from "../error-messages/error-messages";
import CreateRewardPoolLoader from "../loaders/create-reward-pool-loader";
import Select from "../selects/select";
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import TokenSymbol from "../token-symbol/token-symbol";
import CreateRewardPoolModalCex from "./create-reward-pool-modal-cex";
import CreateRewardPoolModalDex from "./create-reward-pool-modal-dex";
import RewardTokensInfo from "../../assets/json/reward-tokens-info.json";
import { 
  getAreAllERC20,
  READING_ERC20,
  ERROR_ERC20_TOKENA,
  ERROR_ERC20_TOKENB 
} from "../../services/get-are-all-erc20";

import "./create-reward-pool-and-add-rewards.scss";
const dex = exchangeList.exchangeTypes.dex;
const cex = exchangeList.exchangeTypes.cex;

const addressZeroA = RewardTokensInfo.addressZeroA;
const addressZeroB = RewardTokensInfo.addressZeroB;

const CreateRewardPoolAndAddRewards = () => {
  const [exchangeValue, setExchange] = useState("");
  const [pairTokenA, setPairTokenA] = useState("");
  const [pairTokenB, setPairTokenB] = useState("");
  const [pairChainId, setPairChainId] = useState("");
  const [rewardToken, setRewardToken] = useState("");
  const [failAreAllERC20, setFailAreAllERC20] = useState();
  const [rewardTokenMetaData, setRewardTokenMetaData] = useState(null);
  const [networks, setNetworks] = useState([]);
  const [modal, setModal] = useState(false);
  const [allDataEntered, setAllDataEntered] = useState(false);
  const [formValidatedExchange, setFormValidatedExchange] = useState(false);
  const [formValidatedPair, setFormValidatedPair] = useState(false);
  const [lastOptionSelectedParentA, setLastOptionSelectedParentA] = useState(null);
  const [lastOptionSelectedParentB, setLastOptionSelectedParentB] = useState(null);
  const [lastOptionSelectedRewardToken, setLastOptionSelectedRewardToken] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isOnMMProcess, setIsOnMMProcess] = useState(false);

  //Used by Add rewards
  const [showAddRewardsModal, setShowAddRewardsModal] = useState(false);
  const [formValidatedRewards, setFormValidatedRewards] = useState(false);
  const [rewardsAmount, setRewardsAmount] = useState("");
  const [rewardsAmountRaw, setRewardsAmountRaw] = useState("");
  const [numOfEpoch, setNumOfEpoch] = useState("");
  
  const { isConnected, address: userAddress } = useAccount();
  //IMPORTANT: useSigner && useAccount are needed two for not rerender unusefully
  const { status: signerStatus, data: signerData } = useSigner();
  const provider = useProvider({chainId: pairChainId});
  const isInvalidNetwork = useInvalidNetwork();
  const rewardTokens = useSelector((state) => state.rewardTokens.default);
  const dispatch = useDispatch();

  const Toggle = () => setModal(!modal);
  const resetForm = () => {
    setPairTokenA("");
    setPairTokenB("");
    setPairChainId("");
    setNetworks([]);
    setRewardToken("");
    setRewardsAmount("");
    setRewardsAmountRaw("");
    setNumOfEpoch("");
    setFailAreAllERC20();
    setModal(false);
    setLastOptionSelectedParentA({});
    setLastOptionSelectedParentB({});
    setFormValidatedExchange(false);
    setFormValidatedPair(false);
    setFormValidatedRewards(false);
    setAllDataEntered(false);
  };
  const handleTokenChange = (token, position) => {
    setFailAreAllERC20(READING_ERC20);
    if (position === "A") {
      setPairTokenA(token);
    } else {
      setPairTokenB(token);
    }
  };
  const handleExchangeChange = exchange => {
    resetForm();
    setExchange(exchange);
    let networksInsideFn = networksListByExchange[exchange];
    if(process.env.REACT_APP_NODE_ENV === 'prod' && exchange === 'uniswap') {
      networksInsideFn.pop(); // in prod don't want see mumbai network on uni
    }
    setNetworks(networksInsideFn);
    setRewardToken("");
    setIsOnMMProcess(false);

    if(networksListByExchange[exchange].length === 0) {
      setFormValidatedExchange(false);
      NotificationManager.warning(
        "This option is not yet available",
        "Warning",
        process.env.NOTIFICATION_TIME
      );
    } else {
      setFormValidatedExchange(true);
    }
  };

  const handlePairChainIdChanged = (chainId) => {
    if (typeof chainId === "string") {
      chainId = parseInt(chainId);
    }
    setPairChainId(chainId);
    setPairTokenA("");
    setPairTokenB("");
    setRewardToken("");
    setFailAreAllERC20();
    setRewardTokenMetaData(null);
    setFormValidatedPair(false);
    setFormValidatedRewards(false);
    setAllDataEntered(false);
    setIsOnMMProcess(false);
  };

  const handlePairPopup = async () => {
    setPairTokenA(pairTokenA.trim());
    setPairTokenB(pairTokenB.trim());
    const lowerPairTokenA = pairTokenA.toLowerCase();
    const lowerPairTokenB = pairTokenB.toLowerCase();

    let flag = true;
    if (pairTokenA === "" || pairTokenB === "") {
      flag = false;
      NotificationManager.error(
        "Must be indicate two token addresses in this pool",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (
      flag &&
      pairTokenA === ethers.constants.AddressZero &&
      pairTokenB === ethers.constants.AddressZero
    ) {
      flag = false;
      NotificationManager.error(
        "Sorry, These two tokens are unpairable",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && pairTokenA === ethers.constants.AddressZero) {
      flag = false;
      NotificationManager.error(
        "Sorry, but we don't have the equivalent address in layer 2 of token A",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && pairTokenB === ethers.constants.AddressZero) {
      flag = false;
      NotificationManager.error(
        "Sorry, but we don't have the equivalent address in layer 2 of token B",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && lowerPairTokenA === lowerPairTokenB) {
      flag = false;
      NotificationManager.error(
        "Token addresses must be different",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && !ethers.utils.isAddress(pairTokenA)) {
      flag = false;
      NotificationManager.error(
        pairTokenA + " is not a valid address",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && !ethers.utils.isAddress(pairTokenB)) {
      flag = false;
      NotificationManager.error(
        pairTokenB + " is not a valid address",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if (flag && rewardToken === "") {
      flag = false;
      NotificationManager.error(
        "Must be indicate a Reward token for this pool",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && failAreAllERC20 === ERROR_ERC20_TOKENA) {
      flag = false;
      NotificationManager.error(
        "TokenA specified is not ERC20 contract",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }

    if(flag && failAreAllERC20 === ERROR_ERC20_TOKENB) {
      flag = false;
      NotificationManager.error(
        "TokenB specified is not ERC20 contract",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    }
    
    if(flag){
      if (rewardToken === addressZeroA) {
        setRewardToken(ethers.utils.getAddress(pairTokenA));
      } else if (rewardToken === addressZeroB) {
        setRewardToken(ethers.utils.getAddress(pairTokenB));
      }
      setFormValidatedPair(true);
      setPairTokenA(pairTokenA);
      setPairTokenB(pairTokenB);
      Toggle();
    } else {
      setAllDataEntered(false);
    }
  };

  const handleCancelPopup = () => {
    if (!formValidatedPair) {
      setPairChainId("");
      setPairTokenA("");
      setPairTokenB("");
      setRewardToken("");
      setFailAreAllERC20();
      setRewardTokenMetaData(null);
      setAllDataEntered(false);
    }
    Toggle();
  };
  const handleOpenModal = () => {
    Toggle();
  };
  const handleAddRewardsCallback = ({
    rewardsAmountRaw,
    rewardsAmount,
    numOfEpoch,
  }) => {
    setShowAddRewardsModal(!showAddRewardsModal);
    setFormValidatedRewards(true);
    setRewardsAmountRaw(rewardsAmountRaw);
    setRewardsAmount(rewardsAmount);
    setNumOfEpoch(numOfEpoch);
  };

  let rewardCurrencyItems = getAllRewardTokensForSelect(rewardTokens);

  const args = [
    exchangeValue,
    pairTokenA,
    pairTokenB,
    rewardToken,
    pairChainId,
    rewardsAmount,
    numOfEpoch,
    1,
  ];
  const { config, error: prepareError } = usePrepareContractWrite({
    address: process.env.REACT_APP_FACTORY_ADDRESS,
    abi: factoryABI,
    functionName: "createDynamicPoolAndAddRewards",
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    args,
    enabled: allDataEntered && !isInvalidNetwork && isSubmitting,
    cacheTime: 1,
    onSettled() {
      setSubmitting(false);
    },
  });

  const {
    data,
    write,
    error: writeError,
    isError: writeIsError,
    isLoading: writeIsLoading,
    reset: writeReset,
  } = useContractWrite({
    ...config,
  });

  const {
    data: txData,
    isLoading: waitIsLoading,
    isSuccess: waitIsSuccess,
  } = useWaitForTransaction({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    hash: data?.hash,
  });

  const clearError = () => {
    setAllDataEntered(false);
    setExchange("");
    setNetworks([]);
    setPairChainId("");
    setPairTokenA("");
    setPairTokenB("");
    setRewardToken("");
    setFailAreAllERC20();
    setRewardTokenMetaData(null);
    setFormValidatedExchange(false);
    setFormValidatedPair(false);
    setFormValidatedRewards(false);
    setLastOptionSelectedParentA(null);
    setLastOptionSelectedParentB(null);
  };

  useEffect(() => {
    if(dex.includes(exchangeValue)){ 
      setFormValidatedPair(false);
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      setSubmitting(false);
  
      getAreAllERC20({
        addresses: [
          pairTokenA,
          pairTokenB
        ], 
        provider,
        setFailAreAllERC20,
      });
    }
  }, [
    pairTokenA, 
    pairTokenB, 
    provider
  ]);

  useEffect(() => {
    if (prepareError && allDataEntered) {
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return HandleRevertMessage(prepareError);
    }
  }, [prepareError, allDataEntered, writeReset]);

  useEffect(() => {
    if (writeIsError && allDataEntered) {
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return HandleRevertMessage(writeError);
    }
  }, [writeError, writeIsError, allDataEntered, writeReset]);

  useEffect(() => {
    if (
      allDataEntered &&
      isOnMMProcess &&
      write &&
      isSubmitting &&
      !writeIsLoading &&
      !waitIsLoading
    ) {
      try {
        write();
      } catch (_) {
        writeReset();
        //silence is gold
      }
    }
  }, [
    allDataEntered,
    isOnMMProcess,
    write,
    isSubmitting,
    writeIsLoading,
    waitIsLoading,
    writeReset,
  ]);

  const handleSubmit = (event) => {
    event.preventDefault();
    let flag = true;
    if (!formValidatedExchange || !formValidatedPair) {
      setRewardToken("");
      setFailAreAllERC20();
      flag = false;
    }

    if (flag && !formValidatedRewards) {
      flag = false;
    }

    setAllDataEntered(flag);
    setIsOnMMProcess(flag);
    setSubmitting(flag);
  };
  if (isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork} />
  }

  if (
    !(
      signerStatus !== "loading" &&
      (!userAddress ||
        (signerData && signerData._address === userAddress))
    )
  ) {
    if (formValidatedPair || formValidatedExchange) {
      setAllDataEntered(false);
      setIsOnMMProcess(false);
      writeReset();
      clearError();
    }
    return <CreateRewardPoolLoader />;
  }

  if (!waitIsLoading && waitIsSuccess && isOnMMProcess) {
    if (txData.status === 1) {
      let poolAddress = "0x" + txData.logs[0].topics[1].substring(26);
      dispatch(refresh({ name: "poolCounter", value: true }));
      return <Navigate to={`/pool/${poolAddress}`} />;
    } else {
      setAllDataEntered(false);
      NotificationManager.error(
        "Error while creating the pool, please try again",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
      setIsOnMMProcess(false);
      writeReset();
      clearError();
      return;
    }
  }

  let disableForm = !!waitIsLoading || !!writeIsLoading || !!isOnMMProcess;
  let exchangeTitle;
  if (disableForm) {
    // eslint-disable-next-line
    exchangesList.tags.map((el, index) => {
      if (el.key === exchangeValue) {
        exchangeTitle = exchangesList.tags[index]["title"];
      }
    });
  }
  return (
    <div className="d-flex justify-content-center">
      <Helmet>
        <title>Create rewards pool | zkMakers</title>
      </Helmet>
      <div className="dark-box create-pool-box">
        <div className="row">
          <div className="col-12">
            <h5>New Reward Pool</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <p>
              A reward pool is created by generating a new smart contract that
              is specific to a particular token pair on a specific exchange. To
              keep smart contract active or valid for certifying trading
              activity, it must have rewards for the current epoch.
            </p>
          </div>
        </div>

        <hr />
        <div className="col-12">
          {isConnected ? (
            <>
              <div className="row col-6 mx-auto">
                <div className="col-12 center">
                  {!disableForm ? (
                    <Select
                      onChange={handleExchangeChange}
                      title="Select an Exchange…"
                      items={exchangesList.tags}
                      defaultValue={exchangeValue}
                    />
                  ) : (
                    <span className="span-disabled">{exchangeTitle}</span>
                  )}
                </div>
                <div className="col-12 center pt-20">
                  {formValidatedExchange &&
                    (!disableForm ? (
                      <button className="pair-btn" onClick={handleOpenModal}>
                        {!formValidatedPair ? (
                          <>Create a Pair…</>
                        ) : (
                          <>
                            <TokenSymbol
                              address={pairTokenA}
                              chainId={pairChainId}
                              noLink={true}
                              addRewardTokenData={true}
                            />{" "}
                            /
                            <TokenSymbol
                              address={pairTokenB}
                              chainId={pairChainId}
                              noLink={true}
                              addRewardTokenData={true}
                            />
                            {dex.includes(exchangeValue) && (
                              <ChainLogo chainId={pairChainId} height={24} />
                            )}
                          </>
                        )}
                      </button>
                    ) : (
                      <span className="span-disabled">
                        <TokenSymbol
                          address={pairTokenA}
                          chainId={pairChainId}
                          noLink={true}
                          addRewardTokenData={true}
                        />{" "}
                        /
                        <TokenSymbol
                          address={pairTokenB}
                          chainId={pairChainId}
                          noLink={true}
                          addRewardTokenData={true}
                        />
                        {dex.includes(exchangeValue) && (
                          <ChainLogo chainId={parseInt(pairChainId)} height={24}/>
                        )}
                      </span>
                    ))}
                </div>
                {formValidatedExchange &&
                  formValidatedPair &&
                  rewardToken &&
                  typeof rewardTokenMetaData === "object" &&
                  (disableForm 
                    ? <>
                        <div className="col-12 center pt-20">
                          <div className="span-disabled">
                            {rewardTokenMetaData.title}{" "}
                          </div>
                        </div>
                        <div className="col-12 center pt-20">
                          <div className="span-disabled">
                            {rewardsAmountRaw} {" "}
                            {rewardTokenMetaData.title} | Epochs: {numOfEpoch}
                          </div>
                        </div>
                      </>
                    : <>
                        <div className="col-12 center pt-20">
                          <button className="pair-btn" onClick={handleOpenModal}>
                            {rewardTokenMetaData?.title}
                          </button>
                        </div>
                        <div className="col-12 center pt-20">
                          <button
                            className="pair-btn"
                            onClick={() =>
                              setShowAddRewardsModal(!showAddRewardsModal)
                            }
                          >
                            {formValidatedRewards ? (
                              <>
                                {rewardsAmountRaw} {" "}
                                {rewardTokenMetaData?.title} | Epochs: {numOfEpoch}
                              </>
                            ) : (
                              <>Add Rewards...</>
                            )}
                          </button>
                        </div>
                      </>
                  )}
              </div>
              <hr />
              <div className="row mt-3">
                <div className="col-12 end">
                  {formValidatedExchange &&
                    formValidatedPair &&
                    formValidatedRewards &&
                    (disableForm ? (
                      <Button
                        disabled={true}
                        text="Creating Pool..."
                        type="button-disabled"
                      />
                    ) : (
                      <Button
                        id="button-create-pool"
                        onClick={handleSubmit}
                        text="Create Pool"
                        type="button-light"
                      />
                    ))}
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <ConnectYourWalletFirst noMargins={true} />
            </div>
          )}
        </div>
      </div>
      {dex.includes(exchangeValue) && (
        <CreateRewardPoolModalDex
          handleCancelPopup={handleCancelPopup}
          handlePairPopup={handlePairPopup}
          handlePairChainIdChanged={handlePairChainIdChanged}
          handleTokenChange={handleTokenChange}
          networks={networks}
          modal={modal}
          pairChainId={pairChainId}
          pairTokenA={pairTokenA}
          pairTokenB={pairTokenB}
          isConnected={isConnected}
          rewardToken={rewardToken}
          failAreAllERC20={failAreAllERC20}
          setRewardToken={setRewardToken}
          setRewardTokenMetaData={setRewardTokenMetaData}
          rewardCurrencyItems={rewardCurrencyItems}
        />
      )}

      {cex.includes(exchangeValue) && (
        <CreateRewardPoolModalCex
          handleCancelPopup={handleCancelPopup}
          handlePairPopup={handlePairPopup}
          modal={modal}
          setPairTokenA={setPairTokenA}
          setPairTokenB={setPairTokenB}
          setPairChainId={setPairChainId}
          setLastOptionSelectedParentA={setLastOptionSelectedParentA}
          setLastOptionSelectedParentB={setLastOptionSelectedParentB}
          setLastOptionSelectedRewardToken={setLastOptionSelectedRewardToken}
          lastOptionSelectedParentA={lastOptionSelectedParentA}
          lastOptionSelectedParentB={lastOptionSelectedParentB}
          lastOptionSelectedRewardToken={lastOptionSelectedRewardToken}
          setFormValidatedPair={setFormValidatedPair}
          rewardToken={rewardToken}
          rewardCurrencyItems={rewardCurrencyItems}
          setRewardToken={setRewardToken}
          setRewardTokenMetaData={setRewardTokenMetaData}
          isConnected={isConnected}
          pairChainId={pairChainId}
          pairTokenA={pairTokenA}
          pairTokenB={pairTokenB}
        />
      )}

      {showAddRewardsModal ? (
        <AddRewards
          showModal={showAddRewardsModal}
          rewardToken={rewardToken}
          userAddress={userAddress}
          handleAddRewardsCallback={handleAddRewardsCallback}
          rewardsAmountDefaultRaw={rewardsAmountRaw}
          rewardsAmountDefault={rewardsAmount}
          numOfEpochDefault={numOfEpoch}
          setFormValidatedRewards={setFormValidatedRewards}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CreateRewardPoolAndAddRewards;
