import DollarComponent from "../basics/dollar-component";
import { useEpochVolumeByPoolId } from "../../services/use-epoch-volume-by-pool-id";

const EpochTVCByPool = ({ 
  poolAddress, 
  currentEpoch, 
  startDate 
}) => {
  const { 
    data: amountUsd, 
    isLoading: isLoadingPoolData 
  } = useEpochVolumeByPoolId({
      poolAddress, 
      startTimestamp: parseInt(startDate) + ((parseInt(currentEpoch) - 1) * parseInt(process.env.REACT_APP_EPOCH_DURATION)), 
      endTimestamp: Math.round(Date.now()/1000)
  });

  if(!isLoadingPoolData){
    return <DollarComponent amount={amountUsd}/>
  }

  return <>Loading…</>
};

export default EpochTVCByPool;
