import { memo } from "react";

const AllPoolsViewLoader = ({message}) => {
  if(!message) {
    message = "Loading pools…";
  }
    return (
    <>
      <div className="col-12 mt-5">
        <h5 className="loading-pools text-center">
          <span>{message}</span>
        </h5>
      </div>
    </>
  );
}
export default memo(AllPoolsViewLoader);