const Pagination = ({
    page,
    numPages,
    numResults,
    numResultsPerPage,
    handlePageChange
}) => {
    return (
        <>
          <div className="row justify-center">
            {page >= 1 && (
              <>
                <div className="col-4 col-lg-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(0)}
                    className="loading-pools click text-center"
                  >
                    <span>{"<<"}</span>
                  </h5>
                </div>

                <div className="col-4 col-lg-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(page - 1)}
                    className="loading-pools click text-center"
                  >
                    <span>{"<"}</span>
                  </h5>
                </div>
              </>
            )}

            <div className="col-4 col-lg-1 mt-5">
              <h5 className="loading-pools click text-center">
                <span>{page + 1}</span>
              </h5>
            </div>

            {
            (
              numResults > numResultsPerPage && 
              numPages > page
            ) && (
              <>
                <div className="col-4 col-lg-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(page + 1)}
                    className="loading-pools click text-center"
                  >
                    <span>{">"}</span>
                  </h5>
                </div>

                <div className="col-4 col-lg-1 mt-5">
                  <h5
                    onClick={() => handlePageChange(numPages)}
                    className="loading-pools click text-center"
                  >
                    <span>{">>"}</span>
                  </h5>
                </div>
              </>
            )}
          </div>
          <div className="row justify-center text-center pt-20">
            <div className="col-lg-12">
              <span>
                We found {numResults ?? 0} pools
                {numResults > numResultsPerPage && (
                  <>
                    {" "}on {(
                      numPages % numResultsPerPage === 0 
                      ? numPages 
                      : numPages + 1
                    )} pages
                  </>
                )}
              </span>
            </div>
          </div>
        </>
    )
}

export default Pagination;