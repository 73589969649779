import { memo } from "react";

const DataBoxLoader = ({message, message2}) => {
  if(!message) {
    message = "Looking for your new trades on this epoch.";
  }
  if(!message2){
    message2 = "This process can take a few minutes...";
  }
  return (
    <div className="box main_border d-flex flex-column justify-content-center align-items-center">
      <div className="row d-flex mt-3">
        <div className="col-12 w-100 text-center">
          <h5 className="white center">{message}</h5>
            {message2 && 
              <h5 className="white center pt-4">
                {message2}
              </h5>
            }
        </div>
      </div>
    </div>
  )
}

export default memo(DataBoxLoader);