import CreateRewardPoolModal from "./create-reward-pool-modal";
import TokenLogo from "../token-logo/token-logo";
import TokenSymbol from "../token-symbol/token-symbol";
import Select from "../selects/select";
import { ethers } from "ethers";
import { READING_ERC20 } from "../../services/get-are-all-erc20";
import RewardTokensInfo from "../../assets/json/reward-tokens-info.json";
import { getRewardCurrencyItemsChild, getRewardTokenDefault } from "../../services/get-reward-token-default-data";

const CreateRewardPoolModalDex = ({
    handleCancelPopup,
    handlePairPopup,
    handlePairChainIdChanged,
    handleTokenChange,
    networks,
    modal,
    pairChainId,
    pairTokenA,
    pairTokenB,
    isConnected,
    rewardCurrencyItems,
    failAreAllERC20,
    setRewardToken,
    setRewardTokenMetaData,
    rewardToken
}) => {
    const rewardCurrencyItemsChild = getRewardCurrencyItemsChild({
        rewardCurrencyItems,
        pairChainId
    });
    const rewardTokenDefault = getRewardTokenDefault({
        failAreAllERC20,
        pairTokenA,
        pairTokenB,
        rewardToken,
        rewardCurrencyItemsChild
    });

    const handleRewardTokenAddress = (rewardTokenEvent) => {
        setRewardToken((!rewardTokenEvent) ? "" : rewardTokenEvent);
        let metaData = null;
        if(rewardTokenEvent === RewardTokensInfo.addressZeroA) {
            metaData = {
                title: RewardTokensInfo.tokenARewardToken,
            }
        }else if(rewardTokenEvent === RewardTokensInfo.addressZeroB) {
            metaData = {
                title: RewardTokensInfo.tokenBRewardToken,
            }
        }else {
            // eslint-disable-next-line
            rewardCurrencyItems.map((el) => {
                if(el.key === rewardTokenEvent) {
                    metaData = {
                        title: el.title,
                    }
                }
            });
        }
        setRewardTokenMetaData(metaData);
    }

    return (
        <CreateRewardPoolModal
        show={modal}
        save={handlePairPopup}
        cancel={handleCancelPopup}
        isConnected={isConnected}
        isCheckingAddresses={failAreAllERC20 === READING_ERC20}
        >
            <div className="row">
                <div className="col-12 text-center mt-3">
                    {networks.length > 0 && (
                    <Select
                        onChange={handlePairChainIdChanged}
                        title="Chain…"
                        items={networks}
                        defaultChecked={pairChainId?.toString()}
                    />
                    )}
                </div>
            </div>
            {pairChainId ? (
            <>
                <div className="row">
                    <div className="col-12 text-center mt-3">
                        <input
                        maxLength="42"
                        placeholder="Address token A"
                        value={pairTokenA}
                        onChange={(e) => handleTokenChange(e.target.value, "A")}
                        type="text"
                        className="create-pool-input"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-3">
                        <input
                        maxLength="42"
                        placeholder="Address token B"
                        value={pairTokenB}
                        onChange={(e) => handleTokenChange(e.target.value, "B")}
                        type="text"
                        className="create-pool-input"
                        />
                    </div>
                </div>
            </>
            ) : (
            <></>
            )}

            <div className="row mt-4">
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 center mt-2">
                    {pairTokenA && pairChainId ? (
                    <h4>
                        {ethers.utils.isAddress(pairTokenA) 
                            ? <TokenLogo 
                                address={pairTokenA} 
                                chainId={pairChainId} 
                                addRewardTokenData={true}
                            />
                            :<></>
                        }
                        <span className="white">
                        {ethers.utils.isAddress(pairTokenA) 
                            ? <TokenSymbol 
                                address={pairTokenA} 
                                chainId={pairChainId} 
                                addRewardTokenData={true}
                            />
                            : <></>
                        }
                        </span>
                    </h4>
                    ) : pairChainId 
                        ? (
                            <h4>
                                <TokenLogo />
                                <span className="white">Token A</span>
                            </h4>
                        ) : (
                        <></>
                        )
                    }
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-12 center mt-2">
                    {pairTokenB && pairChainId ? (
                    <h4>
                        {ethers.utils.isAddress(pairTokenB) 
                        ? <TokenLogo 
                            address={pairTokenB} 
                            chainId={pairChainId}
                            addRewardTokenData={true} 
                            />
                        : <></>
                        }

                        <span className="white">
                        {ethers.utils.isAddress(pairTokenB) 
                            ? <TokenSymbol 
                                address={pairTokenB} 
                                chainId={pairChainId} 
                                addRewardTokenData={true}
                                />
                            : <></>
                        }
                        </span>
                    </h4>
                    ) : pairChainId 
                    ? (
                    <h4>
                        <TokenLogo />
                        <span className="white">Token B</span>
                    </h4>
                    ) : <></>
                    }
                </div>
            </div>
            {pairChainId 
            ? (
                <div className="row mt-4">
                    <div className="col-12 text-left mt-3">
                        <Select
                            onChange={handleRewardTokenAddress}
                            title="Select Reward Currency…"
                            items={rewardCurrencyItemsChild}
                            defaultChecked={rewardTokenDefault}
                            key={Math.random()}
                        />
                    </div>
                </div>
            ): <></>
            }
        </CreateRewardPoolModal>
    )
}

export default CreateRewardPoolModalDex;
