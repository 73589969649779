import { memo } from "react";

const ChainLogo = ({ chainId, height }) => {

  let src, alt;
  switch(chainId){
    case 1:
    case 5:
      src = "https://assets-cdn.trustwallet.com/blockchains/binance/assets/ETH-1C9/logo.png";
      alt = "ETH Logo";
      break;
    case 56:
    case 97:
      src = "https://assets-cdn.trustwallet.com/blockchains/binance/info/logo.png";
      alt = "BSC Logo";
      break;
    case 137:
    case 80001:
      src = "https://assets-cdn.trustwallet.com/blockchains/polygon/assets/0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270/logo.png";
      alt = "POLYGON Logo";
      break;
    default:
      src = "";
      alt = "Unknown Logo";
      break;
  }

  return (
    <img className="chain-logo-details" height={height} src={src} alt={alt}/>
  )
};

export default memo(ChainLogo);
