import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { create, all } from 'mathjs';
import { ethers } from "ethers";
import { getPriceWbnbInUsds } from "./get-price-wbnb-in-usds";

const mathjs = create(all, {
    number: 'BigNumber',
    precision: 64,
});
const queryRaw = `query getPriceUsd {
    tokens: tokenDayDatas(
        where: {token: CONDITION_TOKEN}
        orderBy: date
        orderDirection: desc
        first: 1
    ) {
        priceUSD
        token {
            id
            decimals
        }
    }
}`;

export async function getPriceUsdFromDex({
    arrTokensWithoutAmountUsd: tokenIds,
}) {
    const pricesResponse = {};
    let arrMissing = [];

    if(!Array.isArray(tokenIds) || tokenIds.length === 0) {
        return pricesResponse;
    };

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        uri: process.env.REACT_APP_PANCAKE_V3_SUBGRAPH_URI
    });

    for(
        let iToken = 0, numTokens = tokenIds.length;
        iToken < numTokens;
        iToken++
    ) {
        const query = queryRaw.replace('CONDITION_TOKEN', "\"" + tokenIds[iToken] + "\"");
        const { data } = await client.query({
            query: gql`${query}`
        }).catch(e => {
            // console.log(e)
        })

        if(data?.tokens.length > 0){
            const tokenAddress = data.tokens[0].token.id.toLowerCase();
            if(mathjs.larger(mathjs.bignumber(data.tokens[0].priceUSD),0)) {
                pricesResponse[tokenAddress] = {
                    priceUsd: data.tokens[0].priceUSD,
                    decimals: data.tokens[0].token.decimals
                };
            }else{
                arrMissing.push(tokenAddress);
            }
        }else{
            arrMissing = tokenIds;
        }
    }

    return {
        tokens: pricesResponse,
        missing: arrMissing
    };
}

export async function getPriceUsdFromSC({
    arrTokensWithoutAmountUsd: tokenIds,
    provider
}) {
    const pricesResponse = {
        tokens: {},
        missing: []
    };
    
    if(!Array.isArray(tokenIds) || tokenIds.length === 0) {
        return pricesResponse;
    };

    let predefinedTokens = JSON.parse(process.env.REACT_APP_PANCAKE_PREDEFINED_TOKENS);

    //get wbnb price dinamically
    predefinedTokens[1].priceUsd = parseFloat(await getPriceWbnbInUsds());

    const pancakeV2RouterAddress = ethers.utils.getAddress(process.env.REACT_APP_PANCAKE_V2_ROUTER_ADDRESS);
    const abi = [
      "function getAmountsOut(uint256 amountIn, address[] path) view returns(uint256[] amounts)"
    ];
    const routerSC = new ethers.Contract(
        pancakeV2RouterAddress, 
        abi, 
        provider
    );

    for(
        let iToken = 0, numTokens = tokenIds.length;
        iToken < numTokens;
        iToken++
    ) {
        let priceUsd;

        for(
            let iPredefinedToken = 0, numPredefinedTokens = predefinedTokens.length;
            iPredefinedToken < numPredefinedTokens;
            iPredefinedToken++
        ) {
            if(tokenIds[iToken] === predefinedTokens[iPredefinedToken].address) {
                priceUsd = mathjs.divide(
                    mathjs.bignumber(predefinedTokens[iPredefinedToken].priceUsd.toString()),
                    mathjs.bignumber(10 ** predefinedTokens[iPredefinedToken].decimals)
                );
                pricesResponse.tokens[tokenIds[iToken]] = {
                    priceUsd,
                    decimals: predefinedTokens[iPredefinedToken].decimals
                };
                
                break;
            } else {
                try {
                    const [, priceRouter ] = await routerSC.getAmountsOut(
                        ethers.utils.parseEther(predefinedTokens[iPredefinedToken].priceUsd.toString()).toString(),
                        [
                            ethers.utils.getAddress(tokenIds[iToken]),
                            ethers.utils.getAddress(predefinedTokens[iPredefinedToken].address),
                        ]
                    );

                    priceUsd = mathjs.divide(
                        mathjs.bignumber(priceRouter.toString()),
                        mathjs.bignumber(10 ** 18)
                    );

                    pricesResponse.tokens[tokenIds[iToken]] = {
                        priceUsd,
                        decimals: 18
                    };
                    break;

                } catch(e) {
                    // console.log(e);
                };
            }
        }

        if(!priceUsd) {
            pricesResponse.missing.push(tokenIds[iToken]);
        }
    }
    return pricesResponse;
}

export async function getPriceUsd({
    arrTokensWithoutAmountUsd,
    provider
}) {
    const responseDex = await getPriceUsdFromDex({
        arrTokensWithoutAmountUsd
    })
    .catch(_ => {
        // console.log(_);
    });

    let tokens = { ...responseDex.tokens };
    const responseSC = await getPriceUsdFromSC({
        arrTokensWithoutAmountUsd: responseDex.missing, 
        provider
    })
    .catch(_ => {
        // console.log(_);
    });

    tokens = { 
        ...tokens, 
        ...responseSC.tokens 
    };
    
    const missing = responseSC.missing.filter(scMissingToken => {
        if(Object.keys(responseDex.tokens)
            .findIndex(dexToken => dexToken === scMissingToken) === -1
        ){
            return scMissingToken;
        }
    });

    if(missing.length > 0) {
        const missingFixed = {};
        missing.forEach(el => {
            missingFixed[el] = {
                decimals: "18",
                priceUsd: "0"
            }
        });
        tokens = {
            ...tokens,
            ...missingFixed
        }
    }
    return tokens;
}