import { memo } from "react";

const AllPoolsViewEmpty = ({message}) => {
  if(!message) {
    message = "No pools match for your search criteria";
  }
  return (
    <div className="col-12 mt-5">
      <h5 className="warning-loading-pools text-center">
        <span>{message}</span>
      </h5>
    </div>
  );
}

export default memo(AllPoolsViewEmpty);