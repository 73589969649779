import { useParams } from "react-router-dom";
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
  erc20ABI,
  useContractReads,
} from "wagmi";
import Button from "../buttons/button";
import DateComponent from "../basics/date-component";
import FactoryABI from "../../assets/abis/factory.json";
import "./data-box.scss";
import { HandleRevertMessage } from "../error-messages/error-messages";
import { useEffect, useState } from "react";
import DataBoxLoader from "../loaders/data-box-loader";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import { NotificationManager } from 'react-notifications';
import { refresh } from "../../features/refresh-slice";
import { useDispatch } from "react-redux";
import { saveLastTx } from "../../features/tx-slice";
import { ethers } from "ethers";

const maxDecimals = 6;

const DataBox = ({ 
  setScreen, 
  certifiedData,
  addressTokenA,
  addressTokenB,
  chainId,
  isConnected

}) => {
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const [ submitButtonClicked, setSubmitButtonClicked ] = useState(false);
  const [ isOnMMProcess, setIsOnMMProcess ] = useState(false);
  const [ decimalsTokenA, setDecimalsTokenA ] = useState(0);
  const [ decimalsTokenB, setDecimalsTokenB ] = useState(0);

  const dispatch = useDispatch();

  const numTrades = certifiedData.lastTrades.length;
  let lastTrades = [];

  const { poolAddress } = useParams();

  let promoterAddress = process.env.REACT_APP_PROMOTER_ADDRESS;

  const localStoragePromoterAddress = localStorage.getItem('promoterAddress');
  if(ethers.utils.isAddress(localStoragePromoterAddress)) {
    promoterAddress = localStoragePromoterAddress;
  }

  const args = [
    poolAddress,
    certifiedData.totalPoints,
    certifiedData.nonce,
    certifiedData.lastProofTime.toString(),
    certifiedData.signature,
    certifiedData.uidHash,
    promoterAddress,
  ];

  useContractReads({
    contracts: [
      {
        address: addressTokenA,
        abi: erc20ABI,
        chainId,
        functionName: "decimals",
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
      },
      {
        address: addressTokenB,
        abi: erc20ABI,
        chainId,
        functionName: "decimals",
        cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
      }
    ],
    enabled: decimalsTokenA === 0 || decimalsTokenB === 0,
    onSuccess(data) {
      setDecimalsTokenA(data[0] <= maxDecimals ? data[0] : maxDecimals);
      setDecimalsTokenB(data[1] <= maxDecimals ? data[1] : maxDecimals);
    }
  });

  const { 
    config, 
    error: prepareError, 
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_FACTORY_ADDRESS,
    abi: FactoryABI,
    functionName: "submitProof",
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    args,
    enabled: isConnected && submitButtonClicked
  });

  const { 
    data, 
    write: writeWrite, 
    isLoading: writeIsLoading,
    isError: writeIsError, 
    error: writeError, 
    reset: writeReset, 
    isSuccess: writeIsSuccess,
  } = useContractWrite({
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    ...config
  });

  const { 
    data: txData, 
    isSuccess: waitIsSuccess, 
    isLoading: waitIsLoading 
  } = useWaitForTransaction({
    hash: data?.hash,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    enabled: isConnected && isOnMMProcess &&  !writeIsError && !writeIsLoading
  });

  useEffect(() => {
    if(writeWrite && submitButtonClicked && !isOnMMProcess) {
      writeWrite();
      setSubmitButtonClicked(false);
      setIsOnMMProcess(true);
    }
  }, [submitButtonClicked, writeWrite, isOnMMProcess]);
  useEffect(() => {
    if (!waitIsLoading && waitIsSuccess && isOnMMProcess) {
    if (txData.status === 1) {
      setSubmitButtonClicked(false);
      setIsOnMMProcess(false);
      writeReset();
      NotificationManager.success("Your trades has been submitted!", 'Done', process.env.NOTIFICATION_TIME);
      dispatch(refresh({name: 'epochTVCByPool', value: true}));
      dispatch(saveLastTx(txData.transactionHash));
      setScreen('fourthScreen');
    }
  }

  },[
   waitIsLoading, waitIsSuccess, isOnMMProcess, writeReset
  ]);

  //handling revert
  if(prepareError && submitButtonClicked) {
    HandleRevertMessage(prepareError);
    writeReset();
    setScreen('secondScreen');
    setSubmitButtonClicked(false);
    setIsOnMMProcess(false);
    return;
  }else if (writeIsError && isOnMMProcess) {
    HandleRevertMessage(writeError);
    writeReset();
    setScreen('secondScreen');
    setSubmitButtonClicked(false);
    setIsOnMMProcess(false);
    return;
  }

  //formatting trades received by the API
  for (let i = 0; i < numTrades; i++) {
    const actTrade = certifiedData.lastTrades[i];
    let numDecimals0, numDecimals1;
    if(actTrade.type === "Buy"){
      numDecimals0 = decimalsTokenB;
      numDecimals1 = decimalsTokenA;
    }else{
      numDecimals0 = decimalsTokenA;
      numDecimals1 = decimalsTokenB;
    }
    const trade = {
      timestamp: actTrade.timestamp / 1000,
      type: actTrade.type,
      amount: parseFloat(actTrade.amount).toFixed(numDecimals0),
      price: parseFloat(actTrade.price).toFixed(numDecimals1),
    };
    lastTrades.push(trade);
  }
  // Handling the password change
  const handleSubmit = (e) => {
    setSubmitButtonClicked(true);
  } 
    

  if (isConnected) {
    if(isOnMMProcess) {
      return <DataBoxLoader message="Waiting for the transaction to complete..."/>
    } else {
      if (!waitIsSuccess) {
        return (
          <div className="dark-box">
            <div className="row">
              <div className="col-12 certify-button d-flex justify-content-end">
                {chain.id.toString() !== process.env.REACT_APP_CHAIN_ID ? (
                  <Button
                    text="Change Network"
                    type="button-light"
                    onClick={() =>
                      switchNetwork?.(Number(process.env.REACT_APP_CHAIN_ID))
                    }
                  />
                ) : (
                  <button
                    id="button-submit-trades-or-orders"
                    className="blue-dark-button"
                    onClick={handleSubmit}
                  >
                    Submit your trades
                  </button>
                )}
              </div>
            </div>
            <div className="box mt-4">
              <div className="scroll-box">
                <table>
                  <thead>
                    <tr>
                      <th className="data-box-title">TIMESTAMP</th>
                      <th className="data-box-title">TYPE</th>
                      <th className="data-box-title">AMOUNT</th>
                      <th className="data-box-title">PRICE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lastTrades.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <DateComponent timestamp={item.timestamp * 1000} />
                          </td>
                          <td
                            className={
                              item.type.substring(0, 3) === "Buy"
                                ? "green"
                                : "red"
                            }
                          >
                            {item.type}
                          </td>
                          <td>{item.amount}</td>
                          <td>{item.price}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12 text-center">
                <p>
                  *Just last 10 trades are displayed. You can check the rest of history on
                  your exchange
                </p>
              </div>
            </div>
          </div>
        );
      } else {
        return <DataBoxLoader message="Well done!"/>
      }
    } 
  } else {
    return (
      <div className="box">
        <ConnectYourWalletFirst/>
      </div>
    );
  }
};

export default DataBox;
