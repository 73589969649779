import "./wave-button.scss";

const WaveButton = ({ text, customLinkClass, customButtonClass, onClick }) => {
    return (
        <a className={customLinkClass} onClick={onClick}>
            <button className={customButtonClass}>{text}</button>
            <div className="liquid"></div>
        </a>
    );
};

export default WaveButton;