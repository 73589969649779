import { useSelector } from "react-redux";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";

const DataCertifiedBox = ({
    isConnected
}) => {

    const { hash } = useSelector(state => state.tx.last);
    const blockExplorers = JSON.parse(process.env.REACT_APP_BLOCK_EXPLORER_URLS);
    const txUrl = (blockExplorers.chainId[Number(process.env.REACT_APP_CHAIN_ID)]).replace('token','tx') + hash;
    const postTweetUrlRaw = "https://twitter.com/intent/tweet?";
    const tweet = 
      "Just certified my trading activity on @zkmakers! 🚀💰\n\n" 
      + txUrl + 
      "\n\n#zkMakers #Trade2Earn";

    const query = new URLSearchParams();
    query.append("text", tweet);
    const postTweetUrl = postTweetUrlRaw + query.toString(); 
    if (isConnected) {
      return (
          <div className="box d-flex flex-column justify-content-center align-items-center">
          <div className="row d-flex mt-3">
            <div className="col-12 w-100 text-center">
              <h5 className="white center">
                Well done! 
              </h5>
              <p>
                Share this operation with your friends on {" "}
                <a
                    className="link"
                    target="_blank"
                    href={postTweetUrl}
                >
                    <img
                    className="twitter-icon"
                    src="/assets/img/twitter-icon.png"
                    />
                </a>
              </p>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="box">
          <ConnectYourWalletFirst/>
        </div>
      );
    }
};

export default DataCertifiedBox;