import { Helmet } from "react-helmet";
import ClaimablePoolsBox from "../claimable-pools-box/claimable-pools-box";
import { useAccount, useSigner } from "wagmi";
import AllPoolsViewLoader from "../../loaders/all-pools-view-loader";
import SwitchNetworkModal from "../../switch-network-modal/switch-network-modal";
import { useInvalidNetwork } from "../../../services/use-invalid-network";
import CopyButton from '../../copy-button/copy-button';

const Admin = () => {
  const { isConnected, address: userAddress } = useAccount(); 
  //IMPORTANT: useSigner && useAccount are needed two for not rerender never <AllPools/> and others
  const { status: signerStatus, data: signerData } = useSigner();
  const signerIsConnecting = !!(
    signerStatus !== "loading" && (
        !userAddress 
        ||
        (signerData && signerData._address === userAddress)
    )
  );
  
  const isInvalidNetwork = useInvalidNetwork();
  if (isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork} />;
  }

  return (
    <>
      <Helmet>
        <title>Rewards For Promoters | zkMakers</title>
      </Helmet>

      <div className="row">
        <div className="col-12">
          <div className="row">
            <div className="col-6">
              <h5 className="white">Rewards For Promoters</h5>
            </div>
            <div className="col-6 end">
              {userAddress && <CopyButton dataToCopy={
                window.location.protocol + '//' + window.location.host + '/?p=' + window.btoa(userAddress)
              } text='Copy your referal link'></CopyButton>}
            </div>
          </div>
        </div>
      </div>

      <div className="row bigbox">
        <div className="col-12">
          {(() => {
            if(
                signerIsConnecting 
            ) {
                if(isConnected){
                    return <ClaimablePoolsBox userAddress={userAddress} />
                }else{
                    return (
                        <div className="box">
                            <div className="row mt-4">
                                <AllPoolsViewLoader message={"Must be connect to see your rewards"}/>
                            </div>
                        </div>
                    )
                }
            }
            return (
                <div className="box">
                    <div className="row mt-4">
                        <AllPoolsViewLoader message={"Connecting…"}/>
                    </div>
                </div>
            )
          })()}
        </div>
      </div>
    </>
  );
};

export default Admin;
