import { memo } from "react";

const PoolBoxLoader = () => {
    return (
        <div className="pool-box">
            <div className="row">
                <div className="col-xl-4 col-12 pl-5">
                    <div className="row mt-3">
                        <h5 className="white center">Loading pool...</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(PoolBoxLoader);