import CreateRewardPoolModal from "./create-reward-pool-modal";
import TokenSelect from "../selects/token-select";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { constants } from "ethers";
import Select from "../selects/select";
import RewardTokensInfo from "../../assets/json/reward-tokens-info.json";
import { getRewardCurrencyItemsChild, getRewardTokenDefault } from "../../services/get-reward-token-default-data";
import { OK_ERC20 } from "../../services/get-are-all-erc20";

const CreateRewardPoolModalCex = forwardRef(function CreateRewardPoolModalCex({
    handleCancelPopup,
    handlePairPopup, 
    modal,
    setPairTokenA,
    setPairTokenB,
    setPairChainId,
    setLastOptionSelectedParentA,
    setLastOptionSelectedParentB,
    lastOptionSelectedParentA,
    lastOptionSelectedParentB,
    setFormValidatedPair,
    isConnected,
    rewardToken,
    setRewardToken,
    setLastOptionSelectedRewardToken,
    setRewardTokenMetaData,
    rewardCurrencyItems,
    pairChainId,
    pairTokenA,
    pairTokenB,
},ref) {
    const [tokenAddressesRawA, setTokenAddressesRawA] = useState(null);
    const [tokenAddressesRawB, setTokenAddressesRawB] = useState(null);
    const [rewardTokenRaw, setRewardTokenRaw] = useState(null);
    const [continueInParent, setContinueInParent] = useState(false);
    const [cancelInParent, setCancelInParent] = useState(false);
    const [lastOptionSelectedChildA, setLastOptionSelectedChildA] = useState({});
    const [lastOptionSelectedChildB, setLastOptionSelectedChildB] = useState({});
    const [pairChainIdChild, setPairChainIdChild] = useState(pairChainId);

    const preselectedRewardCurrencyItemsChild = getRewardCurrencyItemsChild({
        rewardCurrencyItems,
        pairChainId
    });

    const [rewardCurrencyItemsChild, setRewardCurrencyItemsChild] = useState(preselectedRewardCurrencyItemsChild);

    let rewardTokenDefault = getRewardTokenDefault({
        failAreAllERC20: OK_ERC20,
        pairTokenA,
        pairTokenB,
        rewardToken: rewardTokenRaw,
        rewardCurrencyItemsChild: preselectedRewardCurrencyItemsChild
    });

    useEffect(() => {
        if(continueInParent) {
            setContinueInParent(false);
            handlePairPopup();
        }
    },
        [continueInParent, handlePairPopup]
    );

    useEffect(()=>{
        if(cancelInParent) {
            setCancelInParent(false);
            handleCancelPopup();
        }
    },
        [cancelInParent, handleCancelPopup]
    );

    useEffect(()=> {
        if(
            pairChainIdChild === process.env.REACT_APP_CHAIN_ID 
            ||
            (
                tokenAddressesRawA &&
                tokenAddressesRawB &&
                tokenAddressesRawA['binance-smart-chain'] && 
                tokenAddressesRawB['binance-smart-chain']
            )
        ) {
            const newRewardCurrencyItemsChild = getRewardCurrencyItemsChild({
                rewardCurrencyItems,
                pairChainId: Number(process.env.REACT_APP_CHAIN_ID)
            });
            setRewardCurrencyItemsChild(newRewardCurrencyItemsChild);
            rewardTokenDefault = getRewardTokenDefault({
                failAreAllERC20: -1,
                pairTokenA: tokenAddressesRawA['binance-smart-chain'],
                pairTokenB: tokenAddressesRawA['binance-smart-chain'],
                rewardToken,
                rewardCurrencyItemsChild: newRewardCurrencyItemsChild
            });
            setPairChainIdChild(Number(process.env.REACT_APP_CHAIN_ID));
        }else{
            setPairChainIdChild(0);
            setRewardCurrencyItemsChild(rewardCurrencyItems);
            rewardTokenDefault = rewardToken;
        }
    }, [
        tokenAddressesRawA, tokenAddressesRawB
    ]);

    useImperativeHandle(ref, () => ({
        // each key is connected to `ref` as a method name
        // they can execute code directly, or call a local method
        setRewardTokenRaw: (rewardToken) => { setRewardTokenRaw(rewardToken) },
    }));

    const handleTokenAddresses = (tokenAddresses, lastOptionSelected, selectId) => {
        if(selectId === 'A') {
            setLastOptionSelectedChildA(lastOptionSelected, selectId);
            if(tokenAddresses === null) {
                setTokenAddressesRawA(null);
            }else{
                setTokenAddressesRawA(
                    (
                        typeof tokenAddresses === "object" &&
                        (
                            tokenAddresses['ethereum'] ||
                            tokenAddresses['polygon-pos'] || 
                            tokenAddresses['binance-smart-chain']
                        )
                        ? tokenAddresses
                        : {ethereum: constants.AddressZero}
                    )
                );
            }
            setRewardTokenRaw(null);
        }else if(selectId === 'B') {
            setLastOptionSelectedChildB(lastOptionSelected, selectId);
            if(tokenAddresses === null) {
                setTokenAddressesRawB(null);
            }else{
                setTokenAddressesRawB(
                    (
                        typeof tokenAddresses === "object" &&
                        (
                            tokenAddresses['ethereum'] ||
                            tokenAddresses['polygon-pos'] || 
                            tokenAddresses['binance-smart-chain']
                        )
                        ? tokenAddresses
                        : {ethereum: constants.AddressZero}
                    )
                );
            }
            setRewardTokenRaw(null);
        }
    }
    const handleRewardTokenAddress = (event) => {
        setRewardTokenRaw(event ?? null);
    }
    
    const handleRewardTokenPrev = () => {
        setRewardToken(rewardTokenRaw ? rewardTokenRaw : "");
        let metaData = null;

        if(rewardTokenRaw === RewardTokensInfo.addressZeroA) {
            metaData = {
                title: RewardTokensInfo.tokenARewardToken
            }
        }else if(rewardTokenRaw === RewardTokensInfo.addressZeroB) {
            metaData = {
                title: RewardTokensInfo.tokenBRewardToken
            }
        }else{
            // eslint-disable-next-line
            rewardCurrencyItems.map(el => {
                if(el.key === rewardTokenRaw) {
                    metaData =  {
                        title: el.title,
                    }
                }
            });
        }
        setRewardTokenMetaData(metaData);
    }

    const handlePairPopupPrev = () => {
        handleRewardTokenPrev();
        handleTokensPrev();
        setContinueInParent(true);
    }
    const handleTokensPrev = () => {
        let flagTokenA = true;
        let flagTokenB = true;

        if(!tokenAddressesRawA) {
            flagTokenA = false;
            setPairTokenA("");
            setLastOptionSelectedChildA({});
        } 

        if(!tokenAddressesRawB) {
            flagTokenB = false;
            setPairTokenB("");
            setLastOptionSelectedChildB({});
        } 

        if(
            flagTokenA &&
            !tokenAddressesRawA['ethereum'] &&
            !tokenAddressesRawA['binance-smart-chain'] &&
            !tokenAddressesRawA['polygon-pos']
        ) {
            flagTokenA = false;
        } 

        if(
            flagTokenB &&
            !tokenAddressesRawB['ethereum'] &&
            !tokenAddressesRawB['binance-smart-chain'] &&
            !tokenAddressesRawB['polygon-pos']
        ) {
            flagTokenB = false;
        } 

        if(flagTokenA && flagTokenB) {
            if(
                tokenAddressesRawA['binance-smart-chain'] && 
                tokenAddressesRawB['binance-smart-chain']
            ) {
                setPairChainId(56);
                setPairTokenA(tokenAddressesRawA['binance-smart-chain']);
                setPairTokenB(tokenAddressesRawB['binance-smart-chain']);
            }else if(
                tokenAddressesRawA['ethereum'] && 
                tokenAddressesRawB['ethereum']
            ) {
                setPairChainId(1);
                setPairTokenA(tokenAddressesRawA['ethereum']);
                setPairTokenB(tokenAddressesRawB['ethereum']);
            }else if(
                tokenAddressesRawA['polygon-pos'] && 
                tokenAddressesRawB['polygon-pos']
            ){
                setPairChainId(137);
                setPairTokenA(tokenAddressesRawA['polygon-pos']);
                setPairTokenB(tokenAddressesRawB['polygon-pos']);
            }else{
                setPairChainId(1);
                setPairTokenA(constants.AddressZero);
                setPairTokenB(constants.AddressZero);
            }
            setLastOptionSelectedParentA(lastOptionSelectedChildA);
            setLastOptionSelectedParentB(lastOptionSelectedChildB);
        }
    }

    const handleCancelPopupChild = () => {
        setLastOptionSelectedParentA({});
        setLastOptionSelectedParentB({});
        setLastOptionSelectedRewardToken(null);
        setFormValidatedPair(false);
        setCancelInParent(true);
        setRewardTokenRaw(null);
    }
    
    return (
        <CreateRewardPoolModal
        show={modal}
        save={handlePairPopupPrev}
        cancel={handleCancelPopupChild}
        isConnected={isConnected}
        >
            <div className="row">
                <div className="col-12 text-center mt-3">
                    <TokenSelect 
                        handleTokenAddresses={(tokenAddresses, lastOptionSelected) => handleTokenAddresses(tokenAddresses, lastOptionSelected, 'A')}
                        placeholder={"Type Token A…"}
                        defaultValue={lastOptionSelectedParentA}
                    />
                </div>
                <div className="col-12 text-center mt-3">
                    <TokenSelect 
                        handleTokenAddresses={(tokenAddresses, lastOptionSelected) => handleTokenAddresses(tokenAddresses, lastOptionSelected, 'B')}
                        placeholder={"Type Token B…"}
                        defaultValue={lastOptionSelectedParentB}
                    />
                </div>
            </div>
            <div className="col-12 text-left mt-3">
                <Select
                    onChange={handleRewardTokenAddress}
                    title="Select Reward Currency…"
                    items={rewardCurrencyItemsChild}
                    defaultChecked={rewardTokenDefault}
                    key={
                        JSON.stringify(
                            [].concat(
                                [tokenAddressesRawA],
                                [tokenAddressesRawB]
                            )
                        )
                    }
                />
            </div>
        </CreateRewardPoolModal>
    )
});

export default CreateRewardPoolModalCex;
