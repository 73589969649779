import { erc20ABI, useContractRead } from "wagmi";
import "./token-symbol.scss";
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRewardToken } from "../../features/reward-tokens-slice";

const parseSymbol = (symbol) => {
  if (!symbol) {
    return '??';
  }
  const symbolLower = symbol.toLowerCase();

  if (symbolLower === "wbtc") {
    symbol = "BTC";
  } else if (symbolLower === "weth") {
    symbol = "ETH";
  } else if (symbolLower === "wbnb") {
    symbol = "BNB";
  } else if (symbolLower === "wmatic") {
    symbol = "MATIC";
  }
  return symbol;
}

const TokenSymbol = ({ 
  address, 
  chainId, 
  addRewardTokenData,
  noLink 
}) => {

  const addressLower = address.toLowerCase();
  const rewardTokensList = useSelector(state => state.rewardTokens.all);
  
  const isRewardTokenSaved = !!rewardTokensList[addressLower];

  const dispatch = useDispatch();
  const tokenTemplate = {
    address,
    abi: erc20ABI,
    chainId: parseInt(chainId),
    allowFailure: true,
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG
  }
  const blockExplorerUrls = JSON.parse(process.env.REACT_APP_BLOCK_EXPLORER_URLS);
  
  let { data, isLoading: isLoadingSymbol } = useContractRead({
    ...tokenTemplate,
    functionName: "symbol",
    enabled: !isRewardTokenSaved
  });

  let symbol;
  if(isRewardTokenSaved) {
    symbol = rewardTokensList[addressLower].symbol;
  }else{
    symbol = parseSymbol(data);
  }

  const { isLoading: isLoadingDecimals } = useContractRead({
    ...tokenTemplate,
    functionName: "decimals",
    enabled: addRewardTokenData === true  && !isLoadingSymbol && !isRewardTokenSaved,
    onSuccess(decimals) {
      dispatch(addRewardToken({addressLower, symbol, decimals}));
    }
  });

  
  if (isLoadingSymbol || isLoadingDecimals) {
    return <>...</>;
  }

  return (
    <>
      {" "}
      {!noLink 
      ? (
        <a 
          href={blockExplorerUrls.chainId[chainId] + address}
          target="_blank"
          className="token-symbol-link"
          rel="noreferrer noopener"
        >{symbol}</a>
      ):(
        <>{symbol}</>
      )}
    </>
  )
};

export default memo(TokenSymbol);
