import { useState } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { NotificationManager } from 'react-notifications';
import Button from "../buttons/button";
import Modal from "../modal/modal";
import { useEffect } from "react";
import { abreviateAddress } from "../../services/use-abreviate-address";

function Wallet() {
  const [modal, setModal] = useState(false);
  const Toggle = () => setModal(!modal);
  const DisconnectWallet = () => {
    disconnect();
    Toggle();
  };
  const { address, isConnected } = useAccount();
  const { connect, connectors, error } = useConnect();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    if(
      error 
      &&
      typeof error === 'object'
      &&
      typeof error.message === 'string'
      )
    NotificationManager.error(error.message, 'Error!', process.env.NOTIFICATION_TIME);
  },[
    error
  ]);

  if (isConnected) {
    return (
      <>
        <Button
          text={abreviateAddress(address, 5)}
          type="button-light"
          onClick={() => Toggle()}
        />
        <Modal show={modal} close={() => Toggle()} title="Disconnect Wallet" textButton="Close">
          <div className="row">
            <div className="col-12 text-center mt-3">
              <Button
                text="Disconnect wallet"
                type="button-light"
                onClick={() => DisconnectWallet()}
              />
            </div>
          </div>
        </Modal>
      </>
    );
  }

  return (
    <>
      <Button
        id="button-connect-wallet"
        text="Connect wallet"
        type="button-light"
        onClick={() => Toggle()}
      />
      <Modal show={modal} close={() => Toggle()} title="Connect Wallet" textButton="Close">
        <div className="row">
          {connectors.map(connector => {
            return (
              <div key={connector.id} className="col-12 text-center mt-3">
                <Button
                  text={connector.name}
                  type="button-light button-connector"
                  onClick={() => {
                    connect({ connector });
                    setModal(!modal);
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
}

export default Wallet;
