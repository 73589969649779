import { Divide as Hamburger } from "hamburger-react";
import { memo, useState } from "react";
import { NavLink } from "react-router-dom";
import Wallet from "../wallet/wallet.jsx";
import "./navbar.scss";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      {/* <a href='https://bonus.bitget.com/zk' target='_blank' rel='noreferrer' ><img src='/assets/img/banner.svg' height='auto' width='100%' alt='Banner'></img></a> */}

      <header>
        <nav className="navbar navbar-light navbar-expand-md bg-faded justify-content-center mb-5">
          <NavLink className="navbar-brand d-flex w-15 me-auto" to="/">
            <img src="/assets/img/logo-min.png" className="logo" alt="logo" />
          </NavLink>

          <button
            className="hamburguer-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsingNavbar"
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </button>
          <div className="navbar-collapse collapse w-100" id="collapsingNavbar">
            <ul className="navbar-nav w-100 justify-content-start">
              <NavLink className="nav-item nav-link" to="/">
                Reward Pools
              </NavLink>
              <NavLink className="nav-item nav-link" to="/createPool">
                Create Reward Pools
              </NavLink>
              {/* <NavLink className="nav-item nav-link" to="/faucet">
                TST Token Faucet
              </NavLink> */}
              <a
                className="nav-item nav-link"
                target="_blank"
                href="https://app.powerbi.com/view?r=eyJrIjoiNTdiOTA1YzUtN2ZmZS00MDhlLWExMWEtNmJiNDU1OGE5MTZkIiwidCI6IjM1MmE0YmFjLWY5NDktNDg0Mi04ZTAwLWU3MmIwNjhjYjAwZCIsImMiOjl9"
                rel="noreferrer noopener"
              >
                Analytics
              </a>
              <NavLink className="nav-item nav-link" to="/rewards">
                Referral
              </NavLink>
            </ul>
            <ul className="nav navbar-nav ms-auto justify-content-end">
              <li className="nav-item">
                <div className="nav-link">
                  <Wallet />
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  );
};

export default memo(Navbar);
