import { NavLink } from "react-router-dom";
import exchangeList from "../../../assets/json/exchanges-list.json";
import ExchangeName from "../../exchange/exchange-name";
import "../../pool-box/pool-box.scss";
import RewardToken from "../../reward-token/reward-token";
import TokenLogo from "../../token-logo/token-logo";
import TokenSymbol from "../../token-symbol/token-symbol";
import ClaimOracleButton from "../buttons/claim-oracle-button";
import ClaimRebateButton from "../buttons/claim-rebate-button";
import "./pool-box-admin.scss";

const PoolBoxAdmin = ({
  addressPool,
  addressTokenA,
  addressTokenB,
  exchangeName,
  chainId,
  userAddress,
  claimableRebate,
  claimableOracle,
  rewardToken,
}) => {
  let addRewardTokenA = false;
  let addRewardTokenB = false;

  return (
    <div className="pool-box">
      <div className="row">
        <div className="col-xl-3 col-12 pl-5">
          <div className="row full-center">
            <div className="col-3">
              <div className="row">
                <div className="col-12">
                  <TokenLogo address={addressTokenA} chainId={chainId} />
                </div>
              </div>
              <div className="row pt-20">
                <div className="col-12">
                  <TokenLogo address={addressTokenB} chainId={chainId} />
                </div>
              </div>
            </div>
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h6 className="pair-name">
                    <TokenSymbol
                      addRewardTokenData={addRewardTokenA}
                      address={addressTokenA}
                      chainId={chainId}
                      noLink={
                        !exchangeList.exchangeTypes.dex.includes(exchangeName)
                      }
                    />{" "}
                    /
                    <TokenSymbol
                      addRewardTokenData={addRewardTokenB}
                      address={addressTokenB}
                      chainId={chainId}
                      noLink={
                        !exchangeList.exchangeTypes.dex.includes(exchangeName)
                      }
                    />
                  </h6>
                </div>
              </div>
              <div className="row pt-10">
                <div className="col-12">
                  <div className="cex-name">
                    <ExchangeName
                      addressTokenA={addressTokenA}
                      addressTokenB={addressTokenB}
                      chainId={chainId}
                      exchangeName={exchangeName}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-1 col-12 separator-container">
          <hr className="separator" />
        </div>

        <div className="col-xl-3 col-12 grid-center">
          <div className="row">
            <div className="col-12">
              <div className="center">
                <p>Claim Rebate Rewards</p>
              </div>
            </div>
          </div>

          {userAddress && claimableRebate ? (
            <>
              <div className="row">
                <div className="col-12 center">
                  <RewardToken
                    amount={claimableRebate.amount}
                    address={rewardToken}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <ClaimRebateButton
                    text="Claim"
                    customButtonClass={"pool-box-button"}
                    addressPool={addressPool}
                    isConnected={!!userAddress}
                    userAddress={userAddress}
                    epochsToClaim={claimableRebate.epochs}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-12 center">
                <RewardToken amount={0} address={rewardToken} />
              </div>
            </div>
          )}
        </div>

        <div className="col-xl-1 col-12 separator-container">
          <hr className="separator" />
        </div>
        <div className="col-xl-3 col-12 grid-center">
          <div className="row full-center">
            <div className="col-6">
              <div className="row">
                <div className="col-12 center">
                  <p>Claim Oracle Rewards</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 center">
                  <h6>
                    {userAddress && claimableOracle ? (
                      <>
                        <RewardToken
                          amount={claimableOracle.amount}
                          address={rewardToken}
                        />
                        <ClaimOracleButton
                          text="Claim"
                          customButtonClass={"pool-box-button"}
                          addressPool={addressPool}
                          isConnected={!!userAddress}
                          userAddress={userAddress}
                          epochsToClaim={claimableOracle.epochs}
                        />
                      </>
                    ) : (
                      <RewardToken amount={0} address={rewardToken} />
                    )}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-1 col-12 separator-container">
          <hr className="separator" />
        </div>
        <div className="col-xl-2 col-12 grid-center">
          <div className="row text-center">
            <div className="col-12">
              <NavLink className="stats-link" to={"/pool/" + addressPool}>
                <button className="pool-box-button">Go to Pool</button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolBoxAdmin;
