import AllClaimableRewards from "../all-claimable-rewards/all-claimable-rewards";
import { useEpochVolume } from "../../services/use-epoch-volume";
import "./portfolio-box.scss";
import ZeroUsd from "../basics/zero-usd";
import DollarComponent from "../basics/dollar-component";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../../features/refresh-slice";

const PortfolioBox = ({ userAddress }) => {
  const refreshAllClaimable = useSelector(
    (state) => state.refresh.allClaimableRewards
  );
  const dispatch = useDispatch();

  if (refreshAllClaimable) {
    dispatch(refresh({ name: "allClaimableRewards", value: false }));
  }

  const { data: epochTVCPortfolio, isLoading: isLoadingEpochTVCPortfolio } =
    useEpochVolume({
      poolType: 1,
      volumeType: "portfolio",
      userAddress,
    });

  return (
    <div className="box">
      <div className="row">
        <div className="col-12 pb-10 grid-end">
          <h5>Your Portfolio</h5>
        </div>
      </div>
      <div className="row">
        <div className="col grid-end">
          <div className="row">
            <div className="col-12">
              <p className="title">
                Weekly TVC{" "}
                <img
                  className="info-icon"
                  src="/assets/img/info-icon.png"
                  alt="info icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Sum of all your certified volume the last 7 days."
                />
              </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 grid-end">
              <h5>
                {isLoadingEpochTVCPortfolio ? (
                  <>Loading…</>
                ) : (
                  <DollarComponent amount={epochTVCPortfolio} />
                )}
              </h5>
            </div>
          </div>
        </div>
        <div className="col grid-end">
          <div className="row">
            <div className="col-12">
              <p className="title">Claimable Rewards </p>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 grid-end">
              <h5>
                {!userAddress ? (
                  <ZeroUsd />
                ) : (
                  <AllClaimableRewards
                    address={userAddress}
                    key={refreshAllClaimable}
                  />
                )}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PortfolioBox;
