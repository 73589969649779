export default function getAllRewardTokensForSelect(rewardTokens) {
    let rewardTokensList = [];
    if(rewardTokens && typeof rewardTokens === "object"){
        Object.keys(rewardTokens).map((addressLower, index) => {
            rewardTokensList.push({
                key: addressLower,
                title: Object.values(rewardTokens)[index].symbol,
                decimals: Object.values(rewardTokens)[index].decimals,
                address: Object.values(rewardTokens)[index].address
            });
        })
    }
    return rewardTokensList;
}