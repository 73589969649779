import { gql, useQuery } from "@apollo/client";
import { create, all } from 'mathjs';
import ChartLoader from "../loaders/chart-loader";
import ChartView from "./chart-view";
import { memo } from "react";

const mathjs = create(all, {
    number: 'BigNumber',
    precision: 64,
  });

const query = gql`query getPointsMintedByEpoch(
    $poolAddress: String,
    $startEpoch: Int,
    $currentEpoch: Int
  ) {
    poolEntity(id: $poolAddress) {
      pointsMinted(
        where: {
          epoch_gte: $startEpoch,
          epoch_lte: $currentEpoch
        }
      ) {
        epoch
        amount
      }
      rewardsByPool(
        where: {
          epoch_gte: $startEpoch,
          epoch_lte: $currentEpoch
        }
      ) {
        epoch
        amount
      }
    }
  }`;

  
const ChartAPY = ({
    poolAddress,
    currentEpoch,
    startEpoch
}) => {

    //Request the data on subgraph
    const { 
      data: apyDataRaw,
      loading: apyDataLoading,
      error: apyDataError
    } = useQuery(query, {
      variables: {
        poolAddress,
        startEpoch,
        currentEpoch
      },
      fetchPolicy: "no-cache"
    });

    //If the data is loading show a loader
    if(
      apyDataLoading ||
      apyDataError ||
      !apyDataRaw ||
      typeof apyDataRaw !== 'object' ||
      !apyDataRaw.poolEntity ||
      typeof apyDataRaw.poolEntity !== 'object' ||
      !apyDataRaw.poolEntity.pointsMinted ||
      !Array.isArray(apyDataRaw.poolEntity.pointsMinted) ||
      !apyDataRaw.poolEntity.rewardsByPool ||
      !Array.isArray(apyDataRaw.poolEntity.rewardsByPool)
    ) {
      return <ChartLoader/>
    }

    const ZERO_BN = mathjs.bignumber('0');
    const amountPointsPerEpochRaw = new Array(currentEpoch + 1).fill(ZERO_BN);
    apyDataRaw.poolEntity.pointsMinted.map(el => {
        amountPointsPerEpochRaw[parseInt(el.epoch)] = 
            mathjs.add(
                amountPointsPerEpochRaw[parseInt(el.epoch)],
                mathjs.bignumber(el.amount)
            )
        }
    );

    const amountRewardsPerEpochRaw = new Array(currentEpoch + 1).fill(ZERO_BN);
    apyDataRaw.poolEntity.rewardsByPool.map(el => {
            amountRewardsPerEpochRaw[parseInt(el.epoch)] = 
                mathjs.add(
                    amountRewardsPerEpochRaw[parseInt(el.epoch)],
                    mathjs.bignumber(el.amount)
                );
        }
    );

    const amountPointsPerEpoch = amountPointsPerEpochRaw.filter((_, index) => index >= startEpoch);
    const amountRewardsPerEpoch = amountRewardsPerEpochRaw.filter((_, index) => index >= startEpoch);

    //Define constants and var for the chart
    const apyData = amountRewardsPerEpoch.map((rewards, index) => 
        {
            if (
                rewards === ZERO_BN 
                ||
                amountPointsPerEpoch[index] === ZERO_BN
            ) {
                return 0;
            } else {
                const proportion = 
                    parseFloat(
                        mathjs.multiply(
                            mathjs.divide(
                                rewards,
                                amountPointsPerEpoch[index]
                            ),
                            mathjs.bignumber('100')
                        )
                    )
                return proportion;
            }
        }
    );

    return (
        <div className="row pt-4">
            <div className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex align-items-end">
                <p className="chart-title">%</p>
            </div>
            <div className="col-12">
                
                <ChartView 
                    startEpoch={startEpoch}
                    currentEpoch={currentEpoch}
                    chartDataRaw={apyData}
                />
            </div>
        </div>
    )
}

export default memo(ChartAPY);