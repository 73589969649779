import { memo } from "react";
import "../token-symbol/token-symbol.scss";

const RewardTokenSymbol = ({ address, symbol, noLink }) => {
  const blockExplorerUrls = JSON.parse(
    process.env.REACT_APP_BLOCK_EXPLORER_URLS
  );

  return noLink ? (
    <span className="token-symbol-link">{symbol}</span>
  ) : (
    <span className="blue">
      <a
        href={
          blockExplorerUrls.chainId[parseInt(process.env.REACT_APP_CHAIN_ID)] +
          address
        }
        target="_blank"
        className="token-symbol-link"
        rel="noreferrer noopener"
      >
        {symbol}
      </a>
    </span>
  );
};

export default memo(RewardTokenSymbol);
