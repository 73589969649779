import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rewardTokensSliceReducers from "../features/reward-tokens-slice";
import searchSliceReducers from "../features/search-slice";
import refreshSliceReducers from "../features/refresh-slice";
import pinnedPoolsSliceReducers from "../features/pinned-pools-slice";
import txSliceReducers from "../features/tx-slice";

export const store = configureStore({
    reducer: {
        rewardTokens: rewardTokensSliceReducers,
        search: searchSliceReducers,
        refresh: refreshSliceReducers,
        pinnedPools: pinnedPoolsSliceReducers,
        tx: txSliceReducers
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});