import { gql, useQuery } from "@apollo/client";
import DollarComponent from "../basics/dollar-component";

const query = `query getTotalWalletCertifVol(
        $poolAddress: String,
        $userAddress: String
    ) {
    poolEntity(id: $poolAddress) {
        pointsMinted(
            where: {
                user: $userAddress
            }
        ) {
            amountUsd
        }
    }
}`;

const TotalWalletCertifVol = ({ 
    userAddress, 
    poolAddress 
}) => {
    const {
        data: amounts,
        loading: loadingAmountsRaw,
        error: errorAmountsRaw
    } = useQuery(gql`${query}`, {
        variables: {
            poolAddress,
            userAddress
        },
        fetchPolicy: "no-cache"
    });

    if(loadingAmountsRaw || errorAmountsRaw) {
        return <>Loading…</>
    }
    let amountUsdTotal = 0;

    if(
        amounts &&
        typeof amounts === 'object' &&
        amounts.poolEntity &&
        typeof amounts.poolEntity === 'object' &&
        Array.isArray(amounts.poolEntity.pointsMinted) && 
        amounts.poolEntity.pointsMinted.length > 0
    ) {
        amounts.poolEntity.pointsMinted.map(el => {
            amountUsdTotal += parseFloat(el.amountUsd);
        });
    }

    return <DollarComponent amount={amountUsdTotal} />
};

export default TotalWalletCertifVol;
