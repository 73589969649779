import { ethers } from "ethers";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAccount, useContractReads, useSigner } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
// import { usePoolValidator } from "../../services/use-pool-validator";
import { Helmet } from "react-helmet";
import { useInvalidNetwork } from "../../services/use-invalid-network";
import AddRewards from "../add-rewards/add-rewards";
import ApiKeyBox from "../api-key-box/api-key-box";
import ZeroUSD from "../basics/zero-usd";
import Button from "../buttons/button";
import ClaimButton from "../buttons/claim-button";
import Chart from "../chart/chart";
import ClaimableRewards from "../claimable-rewards/claimable-rewards";
import DataBox from "../data-box/data-box";
import DataCertifiedBox from "../data-certified-box/data-certified-box";
import EpochWalletCertifVol from "../epoch-certif-vol/epoch-wallet-certif-vol";
import EpochRewards from "../epoch-rewards/epoch-rewards";
import EpochTVCByPool from "../epoch-tvc-by-pool/epoch-tvc-by-pool";
import ExchangeName from "../exchange/exchange-name";
import InfoCertifyBox from "../info-certify-box/info-certify-box";
import PoolDetailLoader from "../loaders/pool-detail-loader";
import PoolsRelated from "../pools-related/pools-related";
import RewardToken from "../reward-token/reward-token";
import Page404 from "../special-pages/page-404";
import SwitchNetworkModal from "../switch-network-modal/switch-network-modal";
import TokenLogo from "../token-logo/token-logo";
import TokenSymbol from "../token-symbol/token-symbol";
import "./pool-detail.scss";
import AllRewards from '../all-rewards/all-rewards';

const Pool = () => {
  const [screen, setScreen] = useState("firstScreen");
  const [certifiedData, setCertifiedData] = useState({});
  const [keyRenderInfoCertify, setKeyRenderInfoCertify] = useState();
  const { poolAddress } = useParams();
  const { isConnected, address: userAddress } = useAccount();
  //IMPORTANT: useSigner && useAccount are needed two for not rerender unusefully
  const { status: signerStatus, data: signerData } = useSigner();
  const [showModal, setShowModal] = useState(false);
  const isValidAddress = ethers.utils.isAddress(poolAddress);
  const isInvalidNetwork = useInvalidNetwork();

  const lmPoolContract = {
    address: poolAddress,
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_LONG,
    enabled: isValidAddress,
  };

  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        functionName: "pairTokenA",
      },
      {
        ...lmPoolContract,
        functionName: "pairTokenB",
      },
      {
        ...lmPoolContract,
        functionName: "getCurrentEpoch",
      },
      {
        ...lmPoolContract,
        functionName: "rewardToken",
      },
      {
        ...lmPoolContract,
        functionName: "chainId",
      },
      {
        ...lmPoolContract,
        functionName: "exchange",
      },
      {
        ...lmPoolContract,
        functionName: "getStartDate",
      },
      {
        ...lmPoolContract,
        functionName: "poolType",
      },
    ],
    enabled: !isInvalidNetwork,
    watch: true,
  });

  const {
    data: epochData,
    isLoading: epochIsLoading,
    isError: epochIsError,
  } = useContractReads({
    contracts: [
      {
        ...lmPoolContract,
        args: [data && Array.isArray(data) ? parseInt(data[2]) : 0],
        functionName: "getEpochEnd",
      },
      {
        ...lmPoolContract,
        args: [data && Array.isArray(data) ? parseInt(data[2]) : 0],
        functionName: "getRewardsPerEpoch",
      },
    ],
    enabled:
      !isInvalidNetwork &&
      typeof data === "object" &&
      Array.isArray(data) &&
      data.length > 0,
    watch: true,
    onSuccess(epochData) {
      const newKeyRenderInfoCertify =
        parseInt(data[2]) + "-" + epochData[0] + "-" + epochData[1];
      if (newKeyRenderInfoCertify !== keyRenderInfoCertify) {
        setKeyRenderInfoCertify(newKeyRenderInfoCertify);
        setShowModal(false);
        setScreen("firstScreen");
      }
    },
  });

  if (isInvalidNetwork) {
    return <SwitchNetworkModal show={isInvalidNetwork} />;
  }

  if (
    !(
      signerStatus !== "loading" &&
      (!userAddress || (signerData && signerData._address === userAddress))
    ) ||
    isLoading ||
    isError ||
    epochIsLoading ||
    epochIsError
  ) {
    return <PoolDetailLoader />;
  }

  let [
    addressTokenA,
    addressTokenB,
    currentEpoch,
    rewardToken,
    chainId,
    exchange,
    startDate,
    poolType,
  ] = data;

  let [endDate, rewardsPerEpoch] = epochData;

  //Carlos: HARDCODED JUST FOR TEST WITH liquidminers-3
  poolType = 1;

  const invalidDataReaded =
    !addressTokenA ||
    !addressTokenB ||
    !currentEpoch ||
    !rewardToken ||
    !chainId ||
    !exchange ||
    !startDate ||
    !poolType ||
    !endDate ||
    !rewardsPerEpoch;

  endDate = parseInt(endDate.toString());
  chainId = parseInt(chainId);
  currentEpoch = parseInt(currentEpoch);
  rewardsPerEpoch = rewardsPerEpoch.toString();

  if (!isInvalidNetwork && (!isValidAddress || invalidDataReaded)) {
    return <Page404 />;
  }

  return (
    <div className="box">
      <Helmet>
        <title>Pool detailed: {poolAddress} | zkMakers</title>
      </Helmet>
      <div className="row mt-4">
        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-12 align-center">
          <TokenLogo
            customClass="pool-detail-img"
            address={addressTokenA}
            chainId={chainId}
          />
          <TokenLogo
            customClass="pool-detail-img right-img"
            address={addressTokenB}
            chainId={chainId}
          />
          <div className="pool-detail-pair pool-detail-pair-name">
            <span>
              <TokenSymbol
                addRewardTokenData={addressTokenA === rewardToken}
                address={addressTokenA}
                chainId={chainId}
              />{" "}
              /
              <TokenSymbol
                addRewardTokenData={addressTokenB === rewardToken}
                address={addressTokenB}
                chainId={chainId}
              />
            </span>
          </div>
        </div>
        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-12">
          <div className="row d-flex justify-content-end">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2">
              {!isLoading && isConnected ? (
                <ClaimButton
                  customButtonClass={"button-light w-100"}
                  text="Claim"
                  addressPool={poolAddress}
                  currentEpoch={currentEpoch}
                  isConnected={isConnected}
                  userAddress={userAddress}
                />
              ) : (
                " "
              )}
            </div>
            {exchange && exchange.toLowerCase() === "uniswap" && (
              <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2">
                <></>
                <NavLink
                  target={"_blank"}
                  className="stats-link end"
                  to={
                    "/swap/uniswap/" +
                    addressTokenA +
                    "/" +
                    addressTokenB +
                    "/" +
                    chainId
                  }
                >
                  <Button text="Trade" type="button-light w-100" />
                </NavLink>
              </div>
            )}

            <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 mt-2 end">
              <Button
                id="button-open-modal-add-rewards"
                onClick={() => setShowModal(!showModal)}
                text="Add Rewards"
                type="button-light w-100"
              />
            </div>
          </div>
        </div>

        <div className="col-12 pl-65 mt-4">
          <ExchangeName
            addressTokenA={addressTokenA}
            addressTokenB={addressTokenB}
            chainId={chainId}
            exchangeName={exchange}
          />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-xl-7 col-md-12 col-lg-7 col-12">
          <div className="row">
            <div className="col-12">
              <span className="pool-detail-title">Reward Pool</span>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-3 col-md-2">
              <div className="row">
                <div className="col-12">
                  <p className="title">Week</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  {isLoading || isError ? (
                    <h5>Loading…</h5>
                  ) : (
                    <h5>{currentEpoch}</h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <p className="title">
                    Weekly TVC{" "}
                    <img
                      className="info-icon"
                      src="/assets/img/info-icon.png"
                      alt="info icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Total value certified last 7 days period."
                    />
                  </p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <h5>
                    <EpochTVCByPool
                      poolAddress={poolAddress}
                      currentEpoch={currentEpoch}
                      startDate={startDate}
                      key={poolAddress}
                    />
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <p className="pool-detail-title-blue">Rewards</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <h5>
                    {isLoading ? (
                      <>Loading…</>
                    ) : (
                      <AllRewards
                        epoch={currentEpoch.toString()}
                        rewardToken={rewardToken}
                        address={poolAddress}
                        chainId={process.env.REACT_APP_CHAIN_ID}
                      />
                    )}
                  </h5>
                </div>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="row">
                <div className="col-12">
                  <p className="pool-detail-title-blue">Weekly Rewards</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12">
                  <h5>
                    {isLoading ? (
                      <>Loading…</>
                    ) : (
                      <EpochRewards
                        epoch={currentEpoch.toString()}
                        rewardToken={rewardToken}
                        address={poolAddress}
                        chainId={process.env.REACT_APP_CHAIN_ID}
                        key={poolAddress}
                      />
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-4 offset-md-6 col-md-6 col-lg-5 offset-lg-7 col-12 offset-xl-1">
          <div className="row">
            <div className="col-12 personal-title d-flex justify-content-end">
              <span className="pool-detail-title">Your Activity</span>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-5">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <p className="title">Weekly Cerfified</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-end">
                  {isLoading ? (
                    <h6>Loading…</h6>
                  ) : userAddress ? (
                    <h5>
                      <EpochWalletCertifVol
                        epoch={currentEpoch}
                        userAddress={userAddress}
                        poolAddress={poolAddress}
                      />
                    </h5>
                  ) : (
                    <h5>
                      <ZeroUSD />
                    </h5>
                  )}
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <p className="title">Claimable rewards</p>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 d-flex justify-content-end">
                  <h5>
                    {isConnected && currentEpoch > 1 ? (
                      isLoading ? (
                        <>Loading…</>
                      ) : (
                        <ClaimableRewards
                          poolAddress={poolAddress}
                          userAddress={userAddress}
                          rewardToken={rewardToken}
                          currentEpoch={currentEpoch}
                        />
                      )
                    ) : (
                      <RewardToken amount={0} address={rewardToken} />
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-6 col-lg-6 col-12">
          {!isLoading && !isError && screen === "firstScreen" && (
            <InfoCertifyBox
              setScreen={setScreen}
              isConnected={isConnected}
              rewardsPerEpoch={rewardsPerEpoch}
              key={keyRenderInfoCertify}
            />
          )}

          {!isLoading && !isError && screen === "secondScreen" && (
            <ApiKeyBox
              setScreen={setScreen}
              setCertifiedData={setCertifiedData}
              endDate={endDate}
              rewardsPerEpoch={rewardsPerEpoch}
              senderAddress={userAddress}
              isConnected={isConnected}
            />
          )}
          {!isLoading && !isError && screen === "thirdScreen" && (
            <DataBox
              setScreen={setScreen}
              certifiedData={certifiedData}
              addressTokenA={addressTokenA}
              addressTokenB={addressTokenB}
              chainId={chainId}
              isConnected={isConnected}
            />
          )}

          {!isLoading && !isError && screen === "fourthScreen" && (
            <DataCertifiedBox isConnected={isConnected} />
          )}
        </div>
        <div className="col-xl-6 col-lg-6 col-12">
          <div className="row">
            <div className="col-12">
              {!isLoading ? (
                <Chart
                poolAddress={poolAddress}
                addressTokenA={addressTokenA}
                addressTokenB={addressTokenB}
                chainId={chainId}
                rewardToken={rewardToken}
                currentEpoch={currentEpoch.toString()}
                  currentEpochStartDate={startDate}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="col-12">
              <div className="box main_border mt-4">
                <PoolsRelated
                  poolAddress={poolAddress}
                  addressTokenA={addressTokenA}
                  addressTokenB={addressTokenB}
                  chainId={chainId}
                  exchange={exchange}
                  poolType={poolType}
                  rewardToken={rewardToken}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {showModal && !isLoading ? (
        <AddRewards
          setScreen={setScreen}
          showModal={showModal}
          poolAddress={poolAddress}
          rewardToken={rewardToken}
          userAddress={userAddress}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Pool;
