import { useContractReads, useProvider } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { gql, useQuery } from "@apollo/client";
import DollarComponent from "../basics/dollar-component";
import { create, all } from 'mathjs';
import { useEffect, useState } from "react";
import { getPriceUsd } from "../../services/get-price-usd-from-external-services";

const mathjs = create(all, {
  number: 'BigNumber',
  precision: 64,
});

const queryPoints = gql`
query getPointsMintedEntities(
        $user: String
        $timestamp1Week: Int
        $timestamp5Weeks: Int
    ) {
    points: pointsMintedEntities (
        where: {
            user: $user
            created_lte: $timestamp1Week
            created_gte: $timestamp5Weeks
        }
    ) {
        pool {
            id
            rewardToken
        }
        epoch
    }
}`;

const AllClaimableRewards = ({ 
    address
}) => {
    address = address.toLowerCase();
    const [ amountUsd, setAmountUsd ] = useState();
    const provider = useProvider({chainId: Number(process.env.REACT_APP_CHAIN_ID)});

    const timestamp1Week = parseInt(Date.now() / 1000);
    const timestamp5Weeks = parseInt(Date.now() / 1000 - (parseInt(process.env.REACT_APP_EPOCH_DURATION) * 4));
    let { data: pointsOnDBLast4Weeks, loading: last4WeeksLoading } = useQuery(
        queryPoints,
        {
            variables: {
                user: address,
                timestamp1Week,
                timestamp5Weeks,
            },
            fetchPolicy: 'no-cache',
            skip: amountUsd !== undefined
        }
    );

  const lmContractTemplate = {
    abi: PoolABI,
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    functionName: "pendingReward"
  }

  const objLmContracts = {};
  let arrLmContracts = [];
  const rewardTokensByPool = {};

  pointsOnDBLast4Weeks?.points.map(item => {
    objLmContracts[item.pool.id + "-" + item.epoch] = {
      ...lmContractTemplate,
      address: item.pool.id,
      args: [address, item.epoch],
    };
    arrLmContracts = Object.values(objLmContracts);
    rewardTokensByPool[item.pool.id] = item.pool.rewardToken;
  });

  const rewardTokens = Array.from(new Set(Object.values(rewardTokensByPool)));
  const { data: dataPendingRewards, isLoading: isLoadingPendingRewards } = useContractReads({
    contracts: arrLmContracts,
    enabled: (
        pointsOnDBLast4Weeks && 
        !last4WeeksLoading && 
        arrLmContracts.length > 0 && 
        amountUsd === undefined
    ),
    watch: true
  });
  useEffect(() => {
    (async() => {
      if(
        Array.isArray(dataPendingRewards) && 
        !isLoadingPendingRewards && 
        !last4WeeksLoading &&
        amountUsd === undefined
      ) {
        if(dataPendingRewards.length === 0) {
          setAmountUsd(0);
          return;
        }

        let amountUsdTotal = mathjs.bignumber(0);
        if(amountUsd === undefined) {
          const tokens = await getPriceUsd({
            arrTokensWithoutAmountUsd: rewardTokens,
            provider
          });

          for(
            let iPendingRewards = 0, numPendingRewards = dataPendingRewards.length;
            iPendingRewards < numPendingRewards;
            iPendingRewards++
          ) {
            const amountRaw = dataPendingRewards[iPendingRewards].toString(); // el amount en rewardToken units
            if(amountRaw === "0") {
                continue;
            }
            const rewardToken = rewardTokensByPool[arrLmContracts[iPendingRewards].address];
            
            const priceUsd = mathjs.bignumber(tokens[rewardToken].priceUsd);
            const decimals = mathjs.bignumber(10 ** parseInt(tokens[rewardToken].decimals));

            const amount = mathjs.divide(
              mathjs.bignumber(amountRaw),
              decimals
            );
            const actAmountUsd = mathjs.format(
                mathjs.multiply(
                priceUsd,
                amount
                ),{notation: 'fixed'}
            );
              
            amountUsdTotal = parseFloat(
                mathjs.add(
                    mathjs.bignumber(amountUsdTotal),
                    actAmountUsd
                )
            );
          }
        }
        setAmountUsd(amountUsdTotal);
      }
    })();
  },[
    JSON.stringify(rewardTokensByPool),
    JSON.stringify(arrLmContracts), 
    dataPendingRewards, 
    isLoadingPendingRewards,
    last4WeeksLoading,
    amountUsd,
    setAmountUsd
  ]);

  if(
    (
      (
        !pointsOnDBLast4Weeks || 
        last4WeeksLoading || 
        isLoadingPendingRewards
      ) 
      &&
      amountUsd === undefined
    ) 
    ||
    amountUsd === undefined
  ){
    return <>Loading…</>
  }
  return <DollarComponent amount={amountUsd}/>

};

export default AllClaimableRewards;
