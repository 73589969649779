import { useAccount, useNetwork} from "wagmi";

export function useInvalidNetwork() {
    const { isConnected } = useAccount();
    const { chain } = useNetwork();

    return (isConnected && 
        (
            !chain 
            ||
            (
                chain 
                &&
                chain?.id !== Number(process.env.REACT_APP_CHAIN_ID)
            )
        )
    )
}