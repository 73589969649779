import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";

const queryVolumeRaw = `query getPoints(
        $limit: Int, 
        $offset: Int,
        $poolType: Int, 
        $startTimestamp: Int, 
        $endTimestamp: Int
    ) {
    pointsMintedEntities (
        first: $limit,
        skip: $offset,
        where: {
            CONDITION_USER
            created_gte: $startTimestamp,
            created_lte: $endTimestamp
        }
        orderBy: created
        orderDirection: asc
    ) {
        amountUsd
        pool {
            poolType
        }
    }
}`;

async function getVolume({
    userAddress,
    client,
    poolType,
    startTimestamp,
    endTimestamp,
    setAmountUsdTotal
}) {
    const limit = 1000;
    let amountUsdTotal = 0;
    let numPointEntities = 0;
    let i = 0;
    const query = queryVolumeRaw.replace(
        'CONDITION_USER',
        userAddress 
            ? "user: \"" + userAddress +"\","
            : ""
    );
    do{
        const { data } = await client.query({
            query: gql`${query}`,
            variables: {
                limit,
                offset: limit * i,
                poolType,
                startTimestamp,
                endTimestamp,
            },
            fetchPolicy: "no-cache"
        });

        numPointEntities = data?.pointsMintedEntities.length;
        if(numPointEntities > 0) {
            data.pointsMintedEntities.map(
                el => {
                    if(parseInt(el.pool.poolType) === poolType){
                        amountUsdTotal += parseFloat(el.amountUsd)
                    }
                }
            );
        }
        i++;
    }while(numPointEntities === limit);
    setAmountUsdTotal(amountUsdTotal);
}

export function useEpochVolume({
    volumeType,
    poolType,
    userAddress = "",
    
}) {
    if(userAddress){
        userAddress = userAddress.toLowerCase();
    }

    const [ amountUsdTotal, setAmountUsdTotal ] = useState();
    const endTimestamp = Math.round(Date.now() / 1000);
    const startTimestamp = endTimestamp - parseInt(process.env.REACT_APP_EPOCH_DURATION);
    const client = useApolloClient();

    if(
        volumeType === 'portfolio' && 
        !userAddress && 
        amountUsdTotal !== 0 
    ){
        setAmountUsdTotal(0);
    }else if(amountUsdTotal === undefined){
        getVolume({
            userAddress,
            client, 
            poolType, 
            startTimestamp, 
            endTimestamp, 
            setAmountUsdTotal
        })
        .catch((_) => {
            // nothing more to say
        });
    }
    return {
        data: amountUsdTotal,
        isLoading: amountUsdTotal === undefined
    }
}