import { useClaimable } from "../../../services/admin/use-claimable-admin-pools";
import AllPoolsView from "../../all-pools/all-pools-view";
import AllPoolsViewEmpty from "../../all-pools/all-pools-view-empty";
import AllPoolsViewLoader from "../../loaders/all-pools-view-loader";
import { useDispatch, useSelector } from "react-redux";
import { refresh } from "../../../features/refresh-slice";

const ClaimablePoolsBox = ({ userAddress }) => {
  // userAddress = "0x116d59E20c770CB87405814e702C5f170CC42609"; // proofVerifier
  // userAddress = "0x1234512345123451234512345123451234512345"; // promoter

  const refreshValue = useSelector(state => state.refresh.adminClaimablePools);
  const dispatch = useDispatch();

  if(refreshValue) {
    dispatch(refresh({name: 'adminClaimablePools', value: false}))
  }
  const { data: claimableRebate, isLoading: isLoadingClaimableRebate } =
    useClaimable({
      refreshValue,
      rewardsOwner: "promoter",
      userAddress,
    });


  const { data: claimableOracle, isLoading: isLoadingClaimableOracle } =
    useClaimable({
      refreshValue,
      rewardsOwner: "proofSigner",
      userAddress,
    });

  return (
    <div className="box">
      <div className="row mt-4">
        {(() => {
          if (
            !isLoadingClaimableRebate 
            &&
            !isLoadingClaimableOracle 
            &&
            typeof claimableRebate === "object" 
            &&
            typeof claimableOracle === "object"
          ) {
            
            let dbPoolsIds = Object.keys(claimableRebate).concat(
              Object.keys(claimableOracle)
            );
            const tmpSet = new Set(dbPoolsIds);

            dbPoolsIds = Array.from(tmpSet);

            const numResultsPerPage = 6;
            const sorting = "asc";

            if (dbPoolsIds.length > 0) {
              return (
                <AllPoolsView
                  claimableOracle={claimableOracle}
                  claimableRebate={claimableRebate}
                  poolBoxType={"pool-box-admin"}
                  sorting={sorting}
                  numResultsPerPage={numResultsPerPage}
                  numPages={Math.floor(dbPoolsIds.length / numResultsPerPage)}
                  numResults={dbPoolsIds.length}
                  dbPoolsIds={dbPoolsIds}
                  userAddress={userAddress}
                />
              );
            } else {
              return <AllPoolsViewEmpty message={"Claimable Pools not found"} />
            }
          } else {
            return <AllPoolsViewLoader />
          }
        })()}
      </div>
    </div>
  );
};

export default ClaimablePoolsBox;
