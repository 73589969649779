import { memo } from "react";
import NumberComponent from "./number-component";

const ZeroUSD = () => {
    return (
        <>
            <span className="dollar">$</span>
            <NumberComponent num={0}/>
        </>
    )
}

export default memo(ZeroUSD);