import { memo } from "react";

const CreateRewardPoolLoader = ({message}) => {
  if(!message) {
    message = "Loading Create reward pool form...";
  }
  return (
    <div className="d-flex justify-content-center">
        <div className="dark-box create-pool-box">
            <div className="row">
                <div className="col-12 center">
                    <h5>{message}</h5>
                </div>
            </div>
        </div>
    </div>
  )
}
export default memo(CreateRewardPoolLoader);