import { useState } from "react";
import Countdown from "react-countdown";
import { useParams } from "react-router-dom";
import { useContractRead, useSignMessage } from "wagmi";
import PoolABI from "../../assets/abis/pool.json";
import { NotificationManager } from "react-notifications";
import exchangeList from "../../assets/json/exchanges-list.json";
import ConnectYourWalletFirst from "../connect-your-wallet-first/connect-your-wallet-first";
import DataBoxLoader from "../loaders/data-box-loader";
import "./api-key-box.scss";

const ApiKeyBox = ({
  setScreen,
  setCertifiedData,
  endDate,
  rewardsPerEpoch,
  senderAddress,
  isConnected,
}) => {
  const timestampMs = Date.now();
  const { poolAddress } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // States for certification
  let prevApiKey = "";
  let prevSecretKey = "";
  let prevPassword = "";
  const exchangeName = localStorage.getItem("exchangeName");
  let rawCredentials = localStorage.getItem(exchangeName + "Credentials");
  if (rawCredentials) {
    rawCredentials = JSON.parse(rawCredentials);
    prevApiKey = rawCredentials.apiKey;
    prevSecretKey = rawCredentials.secretKey;
    prevPassword = rawCredentials.password;
  }
  // const { address: senderAddress, isConnected } = useAccount();
  const [apiKey, setApiKey] = useState(prevApiKey);
  const [secretKey, setSecretKey] = useState(prevSecretKey);
  const [password, setPassword] = useState(prevPassword);

  let isDex = false;

  if (exchangeList.exchangeTypes.dex.includes(exchangeName.toLowerCase())) {
    isDex = true;
  }

  const { data: epoch } = useContractRead({
    address: poolAddress,
    abi: PoolABI,
    functionName: "getCurrentEpoch",
    chainId: Number(process.env.REACT_APP_CHAIN_ID),
    cacheTime: process.env.REACT_APP_WAGMI_CACHETIME_SUPER_SHORT,
  });

  const { signMessage } = useSignMessage({
    message:
      "Sign this message to verify your wallet in zkMakers - " + timestampMs,
    onSettled(data, error) {
      if (error) {
        alert(error);
        return;
      }
      obtainProof({ signature: data, timestampMs });
    },
  });

  const exchangeNumArgs = (() => {
    if (
      exchangeName === "kucoin" ||
      exchangeName === "bitget" ||
      exchangeName === "bitget-futures"
    ) {
      return 3;
    } else if (exchangeName === "gate" || exchangeName === "binance") {
      return 2;
    } else {
      return 0;
    }
  })();

  // Handling the name change
  const handleApiKey = (e) => setApiKey(e.target.value);
  const handleSecretKey = (e) => setSecretKey(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);
  const authWallet = async () => signMessage();

  const obtainProof = async (params) => {
    setIsLoading(true);
    await fetch(
      process.env.REACT_APP_ORACLE_URL +
        "/signatures/create-signed-proof-" +
        (isDex ? "dex" : "cex"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({
          ...params,
          poolAddress,
          senderAddress,
          epoch: parseInt(epoch),
        }),
      }
    )
      .then(async (res) => {
        const response = await res.json();
        setIsLoading(false);
        if (
          (response.statusCode >= 200 && response.statusCode < 300) ||
          !response.statusCode
        ) {
          setCertifiedData(response);
          setScreen("thirdScreen");
        } else {
          NotificationManager.error(
            response.message,
            "Error!",
            process.env.NOTIFICATION_TIME
          );
        }
      })
      .catch(async (e) => {
        const response = await e.message;
        NotificationManager.error(
          response,
          "Error!",
          process.env.NOTIFICATION_TIME
        );
        setIsLoading(false);
      });
  };

  // Handling the form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      (exchangeNumArgs === 3 &&
        (apiKey === "" || secretKey === "" || password === "")) ||
      (exchangeNumArgs === 2 && (apiKey === "" || secretKey === ""))
    ) {
      NotificationManager.error(
        "Please enter all the fields",
        "Error!",
        process.env.NOTIFICATION_TIME
      );
    } else {
      localStorage.setItem(
        exchangeName + "Credentials",
        JSON.stringify({ apiKey, secretKey, password })
      );
      obtainProof({
        exchangeApiKey: apiKey,
        exchangeSecret: secretKey,
        exchangePassword: exchangeNumArgs === 3 ? password : "",
      });
    }
  };

  if (!epoch || isLoading) {
    return <DataBoxLoader />;
  }

  if (isConnected) {
    return (
      <>
        <div className="box main_border">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <div
                className={
                  window.screen.width <= 400
                    ? "box w-100 mt-4"
                    : "box w-80 mt-4"
                }
              >
                <div className="row">
                  <div className="col-12 text-center">
                    <p className="blue-title big_size">
                      Certify your trades and be eligible for rewards! <br />
                      Time is running out
                    </p>
                  </div>
                </div>
                <div className="row pt-10">
                  <div className="col-12 text-center">
                    <div className="badge big_badge">
                      <Countdown
                        date={endDate * 1000}
                        onComplete={() => false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-3">
            <div className="col-12 w-90 text-center">
              {isDex && (
                <p>
                  Please click authenticate wallet before proving your records
                </p>
              )}
              {!isDex && (
                <p>
                  Please enter the exchange API Keys (read only permissions) -{" "}
                  <a
                    className="link"
                    target="_blank"
                    href="https://zkmakers.gitbook.io/zkmakers/introduction/tutorials/how-to-generate-your-api-keys"
                  >
                    Tutorial.
                  </a>
                </p>
              )}
            </div>
          </div>
          {isDex ? (
            <div className="row mt-3">
              <div className="col-12 d-flex justify-content-center">
                <button onClick={authWallet} className="pool-box-button">
                  Authenticate wallet first
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-3 justify-center">
                <form>
                  <div className="col-10 offset-1">
                    <label>API Key</label>
                    <input
                      className="black"
                      onChange={handleApiKey}
                      type="password"
                      value={apiKey}
                    />
                  </div>
                  <div className="col-10 offset-1 private-key-btn mt-3">
                    <label>Secret</label>
                    <input
                      className="black"
                      onChange={handleSecretKey}
                      type="password"
                      value={secretKey}
                    />
                  </div>
                  {exchangeNumArgs === 3 ? (
                    <div className="col-10 offset-1 private-key-btn mt-3">
                      <label>Passphrase</label>
                      <input
                        className="black"
                        onChange={handlePassword}
                        type="password"
                        value={password}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
              <div className="row mt-4">
                <div className="col-12 d-flex justify-content-center">
                  <button onClick={handleSubmit} className="pool-box-button">
                    Prove your records
                  </button>
                </div>
              </div>
              <div className="row d-flex justify-content-center mt-4">
                <div className="col-10 text-center">
                  <p>
                    Remember, the beauty of zkMakers is that we never keep your
                    API Keys.
                  </p>
                  <p className="pt-10">
                    Your APIs are stored in your local device, we use Zero
                    Knowledge technology to safely and privately prove your
                    trading records.
                  </p>
                  <p className="pt-10">
                    Your trading record will be linked to the Web3 wallet that
                    you have connected. Search your wallet in BSC scan and look
                    for a “Submit Proof transaction”. It will contain the
                    trading activity certified.
                  </p>
                  <p className="pt-10">
                    With Zero Knowledge you can prove to a third party the
                    trading activity that you have performed in both CEXes and
                    DEXes. Learn more{" "}
                    <a
                      className="link"
                      target="_blank"
                      href="https://zkmakers.gitbook.io/zkmakers"
                      >
                      here.
                    </a>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div className="box">
        <ConnectYourWalletFirst />
      </div>
    );
  }
};

export default ApiKeyBox;
