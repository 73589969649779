import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const wbnbAddress = JSON.parse(
        process.env.REACT_APP_PANCAKE_PREDEFINED_TOKENS
    )
    [1]
    .address
    .toLowerCase();

const query = `query getPriceWbnbInUsds {
    token(id: "` + wbnbAddress + `") {
        priceUsd: derivedUSD
    }
}`;

export async function getPriceWbnbInUsds() {
    const client = new ApolloClient({
        cache: new InMemoryCache(),
        uri: process.env.REACT_APP_PANCAKE_V3_SUBGRAPH_URI
    });

    const { data } = await client.query({
        query: gql`${query}`,
        fetchPolicy: 'cache-first'
    }).catch(e => {
        // console.log(e);
    });

    return data?.token?.priceUsd || '0';
}