import { memo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import PoolBox from "../pool-box/pool-box";
import PoolBoxAdmin from "../admin/pool-box-admin/pool-box-admin";
import AllPoolsViewLoader from "../loaders/all-pools-view-loader";
import { constants } from "ethers";
import Pagination from "../pagination/pagination";

const queryRaw = `query getPoolEntities(
  $sorting: String,
  $timestamp: Int
  ) {
    poolEntities(
      orderBy: created
      orderDirection: $sorting
      CONDITION_IDS
    ) {
      id
      pairTokenA
      pairTokenB
      chainId
      exchange
      endRewardsDate
      rewardsByPool(
        first: 1
        where: {
          endEpochDate_gte: $timestamp, 
          startEpochDate_lte: $timestamp
        }
      ) {
        endEpochDate
      }
    }
  }
`;

const AllPoolsView = ({
  timestamp,
  sorting,
  numResultsPerPage,
  numPages,
  numResults,
  dbPoolsIds,
  userAddress,
  //admin params:
  poolBoxType,
  claimableRebate,
  claimableOracle,
}) => {
  
  const [ page, setPage ] = useState(0);
  const [ first, setFirst ] = useState((sorting === 'asc') ? 0 : numResults);

  const handlePageChange = (newPageNum) => {
    if(sorting === 'asc') {
      setFirst(numResultsPerPage * newPageNum);
    }else {
      setFirst(numResults - (newPageNum * numResultsPerPage))
    }
    setPage(newPageNum);
  }

  const extractPoolIdsFromArrayDB = () => {
    let arrResponse = [];
    let step = 1;
    if(sorting === 'desc') {
      step = -1;
    }  
    for(let i = 0; i < numResultsPerPage; i++) {
      let iRow = first + (i * step);
      if(sorting === 'desc'){
        iRow--;
      }
      if(dbPoolsIds[iRow]) {
        arrResponse.push(dbPoolsIds[iRow]);
      }
    }
    return arrResponse;
  }

  const poolIds = extractPoolIdsFromArrayDB();
  const CONDITION_IDS = "where: { id_in: [\"" + 
    (
      (poolIds.length > 0) 
      ? poolIds 
      : [constants.AddressZero]
    ).join("\", \"") +
    "\"]}";

  const query = queryRaw.replace("CONDITION_IDS", CONDITION_IDS);
  
  const {
    data: pools,
    loading: loadingPoolsRaw,
    error: errorPoolsRaw,
    observable,

  } = useQuery(gql`${query}`, {
    fetchPolicy: "cache-and-network",
    variables: {
      sorting,
      timestamp: timestamp || 0
    }
  });

  if(pools && !observable.isTornDown && !loadingPoolsRaw && !errorPoolsRaw) {
    const allPools = {};

    pools.poolEntities.map(pool => allPools[pool.id] = pool);
    return (
      <>
        {(
            Object.values(allPools) &&
            Object.values(allPools).map(pool => {
                return (pool.id && (
                    <div className="col-12 mt-3" key={pool.id}>
                        { 
                          poolBoxType !== 'pool-box-admin'
                          ? <PoolBox
                                addressPool={pool.id}
                                addressTokenA={pool.pairTokenA}
                                addressTokenB={pool.pairTokenB}
                                exchangeName={pool.exchange}
                                chainId={parseInt(pool.chainId)}
                                endEpochDate={pool.endRewardsDate}
                                userAddress={userAddress}
                            />
                          : 
                          <PoolBoxAdmin
                                addressPool={pool.id}
                                addressTokenA={pool.pairTokenA}
                                addressTokenB={pool.pairTokenB}
                                exchangeName={pool.exchange}
                                chainId={parseInt(pool.chainId)}
                                userAddress={userAddress}
                                claimableRebate={claimableRebate[pool.id]}
                                claimableOracle={claimableOracle[pool.id]}
                            />
                        }
                  </div>
              ))
          }))}
          <Pagination 
              page={page}
              numPages={numPages}
              numResults={numResults}
              numResultsPerPage={numResultsPerPage}
              handlePageChange={handlePageChange}
          />
      </>
    );
  }else{
    return <AllPoolsViewLoader/>
  }
}

export default memo(AllPoolsView);
