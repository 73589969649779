import ReactDOM from "react-dom";
import Button from "../buttons/button";
import "./modal.scss";

const Modal = ({ show, close, title, children, textButton, hideButton }) => {
  return ReactDOM.createPortal(
    <>
      {show ? (
        <div className="lm_modalContainer" onClick={() => close()}>
          <div className="lm_modal" onClick={(e) => e.stopPropagation()}>
            <div className="lm_modal_header">
              <h2 className="lm_modal_header_title">{title}</h2>
            </div>
            <div className="lm_modal_body">{children}</div>
            {!hideButton && (
              <div className="lm_modal_footer">
                <Button text={textButton} type="button-light" onClick={close} />
              </div>
            )}
            
          </div>
        </div>
      ) : null}
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
