import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import { getPriceUsd } from "./get-price-usd-from-external-services";
import { create, all } from 'mathjs';
import { useProvider } from "wagmi";
import { useSelector } from "react-redux";

const mathjs = create(all, {
    number: 'BigNumber',
    precision: 64,
});

const query = `query getRewardsByPoolEntities(
    $limit: Int, 
    $offset: Int,
    $startTimestamp: Int,
    $endTimestamp: Int
) {
    rewardByPoolEntities (
        first: $limit,
        skip: $offset,
        where: {
            startEpochDate_gte: $startTimestamp, 
            startEpochDate_lte: $endTimestamp
        }
    ) {
        amount
        amountUsd
        pool {
            rewardToken
        }
    }
}`;

async function getRewards({
    client,
    provider,
    rewardTokens,
    startTimestamp,
    endTimestamp,
    setAmountUsdTotal
}) {
    const limit = 1000;
    let amountUsdTotal = 0;
    let numPointEntities = 0;
    let i = 0;

    do{
        const arrTokensWithoutAmountUsd = [];
        const { data } = await client.query({
            query: gql`${query}`,
            variables: {
                limit,
                offset: limit * i,
                startTimestamp,
                endTimestamp,
            },
            fetchPolicy: "no-cache"
        });
        numPointEntities = data?.rewardByPoolEntities.length;

        if(numPointEntities > 0){
            data.rewardByPoolEntities.map(
                el => {
                    if(el.amountUsd === '0'){
                        arrTokensWithoutAmountUsd.push(el.pool.rewardToken);
                    }
                    return amountUsdTotal += parseFloat(el.amountUsd);
                }
            );
            const arrMissingPricesUsd = await getPriceUsd({
                arrTokensWithoutAmountUsd, 
                provider
            })
            .catch(_ => {
                // console.log(_);
                // nothing more to say
            });

            if(
                typeof arrMissingPricesUsd === 'object'
                &&
                Object.keys(arrMissingPricesUsd).length > 0
            ) {
                for(
                    let iData = 0, numData = data.rewardByPoolEntities.length; 
                    iData < numData;
                    iData++
                ){
                    const el = data.rewardByPoolEntities[iData];
                    const iRewardToken = Object.keys(arrMissingPricesUsd)
                        .findIndex(rewardToken => el.pool.rewardToken === rewardToken); 

                    if(iRewardToken !== -1) {
                        const rewardTokenAddress = Object.keys(arrMissingPricesUsd)[iRewardToken];
                        if(
                            !rewardTokenAddress 
                            || 
                            !rewardTokens[rewardTokenAddress]
                            ||
                            typeof rewardTokens[rewardTokenAddress].decimals !== 'number'
                        ){
                            return;
                        }

                        const priceUsdRaw = (
                            (
                                Object.values(arrMissingPricesUsd)[iRewardToken]
                            ).priceUsd
                        ).toString();

                        const priceDecimals = parseInt((
                            Object.values(arrMissingPricesUsd)[iRewardToken]
                        ).decimals);

                        const priceUsd = (mathjs.divide(
                            mathjs.bignumber(priceUsdRaw),
                            mathjs.bignumber(10 ** priceDecimals)
                        )).toString();

                        const amountUsd = mathjs.multiply(
                            mathjs.bignumber(el.amount),
                            mathjs.bignumber(priceUsd)
                        );

                        amountUsdTotal += parseFloat(amountUsd);
                    }
                }
            }
        }
        i++;
    }while(numPointEntities === limit);
    setAmountUsdTotal(amountUsdTotal);
}

export function useWeeklyRewards() {
    const [ amountUsdTotal, setAmountUsdTotal ] = useState();
    const endTimestamp = Math.round(Date.now() / 1000);
    const startTimestamp = endTimestamp - parseInt(process.env.REACT_APP_EPOCH_DURATION);
    const client = useApolloClient();
    const provider = useProvider({chainId: Number(process.env.REACT_APP_CHAIN_ID)});
    const { all: rewardTokens } = useSelector((state) => state.rewardTokens);

    if(amountUsdTotal === undefined){
        getRewards({
            client, 
            provider,
            rewardTokens,
            startTimestamp, 
            endTimestamp, 
            setAmountUsdTotal
        })
        .catch(_ => {
            // console.log(_);
            // nothing more to say
        });
    }
    return {
        data: amountUsdTotal,
        isLoading: amountUsdTotal === undefined
    }
}
