import { gql, useApolloClient } from "@apollo/client";
import { useState } from "react";
import {
    useContractReads
} from "wagmi";
import { create, all } from 'mathjs';
import PoolABI from "../../assets/abis/pool.json";

const mathjs = create(all, {
  number: 'BigNumber',
  precision: 64,
});

const queryRewardsRaw = `query getPoints (
        $limit: Int, 
        $offset: Int,
        $startTimestamp: Int, 
        $endTimestamp: Int
    ) {
    points: CONDITION_REWARDS_OWNER (
        first: $limit,
        skip: $offset,
        where: {
            CONDITION_USER
            created_gte: $startTimestamp,
            created_lte: $endTimestamp
        }
        orderBy: created
        orderDirection: asc
    ) {
        pool {
            id
        }
        epoch
    }
}`;

async function getRewardsFromSubgraph({
    rewardsOwner,
    userAddress,
    client,
    startTimestamp,
    endTimestamp,
    setPoolData
}) {
    const limit = 1000;
    const poolData = {};
    let numPointEntities = 0;
    let i = 0;

    let query = queryRewardsRaw
        .replace(
            'CONDITION_REWARDS_OWNER',
            rewardsOwner === 'promoter' ? 'pointsRewardedPromoterEntities' : 'pointsRewardedProofSignerEntities'
        )
        .replace(
            'CONDITION_USER',
            userAddress 
                ? rewardsOwner + ": \"" + userAddress +"\","
                : ""
        );

    do{
        const { data } = await client.query({
            query: gql`${query}`,
            variables: {
                limit,
                offset: limit * i,
                startTimestamp,
                endTimestamp,
            },
            fetchPolicy: "network-only",
        });

        numPointEntities = data?.points.length;
        if(numPointEntities > 0) {
            data.points.map(
                el => {
                    if(!Array.isArray(poolData[el.pool.id])) {
                        poolData[el.pool.id] = [];
                    }
                    poolData[el.pool.id].push(
                        el.epoch
                    );
                }
            );
        }
        i++;
    }while(numPointEntities === limit);
    setPoolData(poolData);
}

function useRewardsAdded({
    userAddress, 
    rewardsOwner,
    refreshValue
}){
    if(userAddress){
        userAddress = userAddress.toLowerCase();
    }

    const [ poolData, setPoolData ] = useState();
    const endTimestamp = Math.round(Date.now() / 1000);
    const startTimestamp = 0;
    
    const client = useApolloClient();

    if(poolData === undefined || refreshValue) {
        if(userAddress){
            getRewardsFromSubgraph({
                rewardsOwner,
                userAddress,
                client, 
                startTimestamp, 
                endTimestamp, 
                setPoolData
            })
            .catch(_ => {
                // nothing more to say
            });
        }else{
            setPoolData({});
        }
    }

    return {
        data: poolData,
        isLoading: poolData === undefined
    }
}

function formatClaimableDataFromSc({
    dataRaw,
    contracts
}) {
    const dataFormated = {};
    let isCanClaimEpochData = false;
    let canClaimEpoch = true;

    dataRaw.map((el, iData) => {
        isCanClaimEpochData = !isCanClaimEpochData;
        if(isCanClaimEpochData) {
            canClaimEpoch = !!el;
            return;
        }

        //if el === null; epoch was already claimed before or it's invalid epoch requested
        if(el !== null && canClaimEpoch){
            const actAmount = mathjs.bignumber(el.toString()).toString();

            //if actAmount !== '0'; actAmount was already claimed before 
            if(actAmount !== '0'){
                const poolId = contracts[iData].address;
                const epoch = contracts[iData].args[1];

                if(typeof dataFormated[poolId] !== 'object') {
                    dataFormated[poolId] = {};
                }

                if(!dataFormated[poolId].amount) {
                    dataFormated[poolId].amount = '0';
                }

                if(!Array.isArray(dataFormated[poolId].epochs)) {
                    dataFormated[poolId].epochs = [];
                }

                dataFormated[poolId].amount = mathjs.add(
                    mathjs.bignumber(dataFormated[poolId].amount.toString()),
                    mathjs.bignumber(actAmount)
                );

                dataFormated[poolId].amount = mathjs.format(
                    dataFormated[poolId].amount,
                    {notation: 'fixed'}
                );

                if(
                    dataFormated[poolId].epochs.findIndex(
                        el => el === epoch
                    ) 
                    === -1
                ) {
                    dataFormated[poolId].epochs.push(epoch);
                }
            }
        }
    });
    return dataFormated;
}

export function useClaimable({
    refreshValue,
    rewardsOwner, // => 'promoter' | 'proofSigner'
    userAddress,
}) {
    const { 
        data: poolData, 
        isLoading: isLoadingRewards,
    } = useRewardsAdded({
        refreshValue,
        rewardsOwner,
        userAddress,
    });

    //build the objects with which we are going to consult the sc to see the claimable rewards
    const lmContractCommon = {
        abi: PoolABI,
        chainId: Number(process.env.REACT_APP_CHAIN_ID),
    };

    let lmContracts = [];

    if(
        !isLoadingRewards 
        && 
        Object.keys(poolData).length > 0
    ) {
        Object.keys(poolData).map((poolId, iPool) => {
            const actPoolEpochs = Object.values(poolData)[iPool];
            actPoolEpochs.map(epoch => {

                lmContracts.push(
                    {
                        ...lmContractCommon,
                        functionName: "canClaimThisEpoch",
                        address: poolId,
                        args: [parseInt(epoch)]
                    },
                    {
                        ...lmContractCommon,
                        functionName: rewardsOwner === 'promoter' ? "pendingRebateReward" : "pendingOracleReward",
                        address: poolId,
                        args: [userAddress, parseInt(epoch)]
                    }
                );
            });
        });
    }
    //

    //consulted in the sc the claimable rewards
    const { 
        data: claimableRaw, 
        isLoading: isLoadingClaimableRaw
    } = useContractReads({
        contracts: lmContracts,
        enabled: !isLoadingRewards && lmContracts.length > 0,
        cacheOnBlock: false,
        watch: true
    });

    //we will build a new object according to the result obtained from the sc:
    
    //0) if the amount obtained in this epoch for this pool is greater than 0 we will include it, otherwise not.
    //1) for the multiclaim we will need to know which are the epochs for each claimable pool
    //2) add all the amounts for each pool to show it in the pool-box-admin
    let claimablePools = {};
    if(!isLoadingClaimableRaw && claimableRaw) {
        claimablePools = formatClaimableDataFromSc({
            dataRaw: claimableRaw,
            contracts: lmContracts
        });
    }
    return {
        data: claimablePools,
        isLoading: !!(isLoadingClaimableRaw || isLoadingRewards), 
    };
}
