import { createSlice } from '@reduxjs/toolkit';

export const refreshSlice = createSlice({
    name: 'componentsRefreshed',
    initialState: {
        poolCounter: false,
        epochRewards: false,
        adminClaimablePools: false,
        allClaimableRewards: false
    },
    reducers: {
        refresh: (state, action) => {
            state[action.payload.name] = 
                action.payload.value !== undefined 
                ? action.payload.value 
                : Math.random();
        }
    }
});

export const { refresh } = refreshSlice.actions;
const refreshSliceReducers = refreshSlice.reducer;
export default refreshSliceReducers;
