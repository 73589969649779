import Button from "../buttons/button";
import Wallet from "../wallet/wallet";

const ConnectYourWalletFirst = ({ noMargins, isModal, closeFN }) => {
  if (!isModal) {
    return (
      <>
        <div className={"row" + (noMargins ? "" : " mt-5")}>
          <div className={"col-12 center" + (noMargins ? "" : " mt-5")}>
            <h5>Connect your wallet first</h5>
          </div>
        </div>

        <div className="row">
          <div className="col-12 center">
            <Wallet />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="lm_modal_body">
          <div className="row">
            <div className="col-12 center">
              <h5>Connect your wallet first</h5>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 center">
              <Wallet />
            </div>
          </div>
        </div>
        <div className="lm_modal_footer">
          <div>
            <Button text="Close" type="button-light" onClick={closeFN} />
          </div>
        </div>
      </>
    );
  }
};

export default ConnectYourWalletFirst;
